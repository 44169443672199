import React, { useEffect, useState } from "react";
import './nav.css';
import { Link, useNavigate } from "react-router-dom";
import MainIcon from '../../assets/images/google_home.svg';
import HotelIcon from '../../assets/images/google_menu.svg';
import MyIcon from '../../assets/images/google_my.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Cookies from "js-cookie";
const Nav = () =>{
    const [iconActive, setIconActive] = useState("");
    const navigate = useNavigate();
    const auth = getAuth();
    const navItems = [
        { id: 1, to: "/", alt: "knock_space_main_icon", src: MainIcon },
        { id: 2, to: "/search", alt: "knock_space_hotel_icon", src: HotelIcon },
        { 
            id: 3, 
            alt: "knock_space_my_icon", 
            src: MyIcon, 
            onClick: (e) => {
                e.preventDefault();
                const token = Cookies.get('refreshToken');
                if(token){
                    navigate('/mypage');
                }else{
                    navigate('/login');
                }
            }
        }
    ];
    return(
        <div className="nav-container">
            <div className="nav-box">
                {navItems.map(item => (
                    <Link 
                        key={item.id}
                        className="nav-link" 
                        to={item.to} 
                        onClick={(e) => {
                            item.onClick?.(e);
                            setIconActive(item.id);
                        }}>
                        <img
                            alt={item.alt}
                            src={item.src}
                            className={"nav-icon" }
                        />
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default Nav;