import React from "react";
import Search from "../components/Header/Search";

const HotelInfo = () =>{

    return(
        <div id="child">
            <div>
             <Search></Search>
            </div>
        </div>
    )
}

export default HotelInfo;