import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import { deletePayment } from "../../utils/api";
import { StatusEnum } from "../../utils/matchingTable";
import Top from "../../components/Header/Top";
import { formatCurrency } from "../../utils/formatter";

const MyReservationDetailPage = () => {
    const location = useLocation();
    const [order, setOrder] = useState([]);
    const [status, setStatus] = useState('');
    const [amount, setAmount] = useState(0);
    const [reason, setReason] = useState('');
    const [isModal, setIsModal] = useState(false);
    const handleDelete = async () =>{
        console.log("취소를 요청합니다.");
        try {
            const deletedData =  await deletePayment(order.order_id, 1, 10000);
            if(deletedData) {
                alert("취소가 완료되었습니다.");
                setIsModal(false);
            }
        } catch (error) {
            console.error("데이터를 가져오는 중 오류 발생", error);
        }
    }
    useEffect(()=>{
        setOrder(location.state.order)
        setStatus(order.order_status);
    },[order])
    return(
        <div id="child">
            <div>
                <div className="top-section">
                    <Top></Top>
                </div>
                <SCardContainer>
                    <SCardWrapper>
                        <div>
                            <h3>{order.accommodation_name}</h3>
                        </div>
                        <SCardHeaderBottom>
                            {order.accommodation_address}
                        </SCardHeaderBottom>
                        <SCardHeaderCatpion>
                            {order.room_name}
                        </SCardHeaderCatpion>
                        <SCardHeaderCatpion>
                            {order.order_check_in} ~ {order.order_check_out}
                        </SCardHeaderCatpion>
                        <SCardHeaderCatpion>
                            {order.accommodation_contact}
                        </SCardHeaderCatpion>
                        </SCardWrapper>
                    <SCardWrapper>
                        <div>
                            <h4>예약 정보</h4>
                        </div>
                        <SFlexWrapper>
                            <SFlexLeft>
                                예약 번호
                            </SFlexLeft>
                            <SFlexRight>
                                {order.order_id}
                            </SFlexRight>
                        </SFlexWrapper>
                        <SFlexWrapper>
                            <SFlexLeft>
                                예약 상태
                            </SFlexLeft>
                            <SFlexRight>
                                {StatusEnum.get(status)}
                            </SFlexRight>
                        </SFlexWrapper>
                        <SFlexWrapper>
                            <SFlexLeft>
                                예약 날짜
                            </SFlexLeft>
                            <SFlexRight>
                                {order.order_check_in} ~ {order.order_check_out}
                            </SFlexRight>
                        </SFlexWrapper>
                        <SFlexWrapper>
                            <SFlexLeft>
                                예약 상품
                            </SFlexLeft>
                            <SFlexRight>
                            {order.accommodation_name} {order.room_name}
                            </SFlexRight>
                        </SFlexWrapper>
                        <SFlexWrapper>
                            <SFlexLeft>
                                예약자 성함
                            </SFlexLeft>
                            <SFlexRight>
                                {order.user_id}
                            </SFlexRight>
                        </SFlexWrapper>
                        <SFlexWrapper>
                            <SFlexLeft>
                                총 결제 금액
                            </SFlexLeft>
                            <SFlexRight>
                                {formatCurrency(order.total_cost)}원
                            </SFlexRight>
                        </SFlexWrapper>
                    </SCardWrapper>
                    {
                        order.order_status === "completed" ?    
                            <SButtonGrey 
                                onClick={() => setIsModal(true)}
                            >취소 요청</SButtonGrey>
                            :  null
                    }
                                    
                 
                </SCardContainer>
            </div>
            {
                order.order_status ==="completed" ?
                    <Modal
                    isOpen={isModal}
                    height="100vh"
                    backgroundColor="rgb(0 0 0 / 68%);"
                    justifyContent="center">
                        <div>
                            <div className="top-section">
                                <div className="modal-box">
                                    <div className="modal-top-box">
                                        <i class="fa-solid fa-xmark" onClick={() => setIsModal(false)}></i> 
                                    </div>
                                    <h4>
                                        아래 금액이 환불될 예정입니다. 
                                    </h4>
                                    <input className="input-knock" type='text' placeholder='결제 금액' value={parseInt(order.price_cost)+'원'} disabled></input>
                                    <input className="input-knock" type='text' placeholder='취소 사유를 적어주세요.' value={reason} onChange={(e)=> setReason(e.target.value)}></input>
                                    <div>
                                        결제 취소 하시겠습니까?
                                    </div>
                                    <div className="button_cta modal-cancel-button" onClick={handleDelete}>
                                        취소하기
                                    </div> 
                                </div>
                            </div>
                        </div>
                     </Modal>
                    : null
            }
        </div>
    )
}
const SCardContainer = styled.div`
    background-color:#F2F5F7;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding:10px;
    min-height:90vh;
`;
const SCardWrapper = styled.div`
    width:90%;
    height:100%;
    padding:20px 15px;
    background-color:#ffffff;
    border-radius:10px;

`;
const SCardHeaderBottom = styled.div`
    font-size:14px;
    padding :10px 0px;
    border-bottom:1px solid #c4c4c4;
`;
const SCardHeaderCatpion = styled.div`
    font-size:14px;
    line-height:24px;
    color:#979797;
    padding:5px 5px;
    margin:5px 0px;
`;
const SButtonGrey = styled.div`
    width:90%;
    padding:10px;
    text-align:center;
    background-color:white;
    border-radius:20px;
    color:#333;
    font-weight:600;
    cursor:pointer;
`
const SFlexWrapper = styled.div`
    display:flex;
    justify-content: space-between;
    margin: 30px 0px;
`
const SFlexLeft = styled.div`
    color:#747474;
`;

const SFlexRight = styled.div`

`;
const SCancelButton = styled.div`
    width:90%:
`;
const SInput = styled.input`
    width:calc(100% - 20px);
    height:20px;
    border-radius:5px;
    border:1px solid #ccc;
    margin-bottom: 10px;
    padding: 8px 10px;
    font-size: 14px;
`
export default MyReservationDetailPage;