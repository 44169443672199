import moment from 'moment-timezone';


export const changeTimeToKR = (time) => {
    const utcMoment = moment.utc(time);
    // 한국 시간대로 변환
    const krMoment = utcMoment.tz('Asia/Seoul');
    
    // 원하는 형식으로 포맷
    const formattedKrTime = krMoment.format('YYYY-MM-DD HH시mm분');
    return formattedKrTime;
}

export const changeToKR = (date) => {
    const startDateString = moment(date).format('YYYY-MM-DD');

    // const utcMoment = moment.utc(date);
    // const krMoment = utcMoment.tz('Asia/Seoul');
    // const formattedKrTime = krMoment.format('YYYY-MM-DD');
    return startDateString;
  }

export const formatCurrency = (amount) => {
    // toLocaleString 메서드를 사용하여 숫자를 통화 형식으로 변환
    return Math.floor(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatCntDay = (start, end, cnt) => {
    if(start && end){
        const timeDiff = new Date(end) - new Date(start);
        const dayDiff = timeDiff / (1000 * 60 * 60 * 24);
        return dayDiff;
    }
    else if(start && cnt){
        const orderCheckOutDate = new Date(start);
        orderCheckOutDate.setDate(orderCheckOutDate.getDate() - 1);
        const newDateAsString = orderCheckOutDate.toISOString().split('T')[0];
        return newDateAsString;
    }
}