import React from "react";
import { styled } from "styled-components";
import PrevIcon from "../../assets/images/prev_icon.svg";
import HomeIcon from "../../assets/images/home_icon.png";
import { useNavigate } from "react-router-dom";

const Top = (props) =>{
    const navigate = useNavigate();
    const movePrev = () =>{
        navigate(-1);
    }
    const moveHome = () =>{
            navigate('/');
    }
    return(
        <STopBox>
            <STopBoxLeft>
                <SImgWrapper alt="knock_space_banner" src={PrevIcon} onClick={ movePrev } />
                <div>{props.title}</div>
            </STopBoxLeft>
            {
                props.title &&
                    <SImgWrapper2 src={HomeIcon} onClick={ moveHome }/>
            }
        </STopBox>
    )
}
const STopBox = styled.div`
    display:flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    justify-content: space-between
`;
const STopBoxLeft = styled.div`
    display:flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
`;
const SImgWrapper = styled.img`
    width:40px;
    cursor:pointer;
`;
const SImgWrapper2 = styled.img`
    width:24px;
    cursor:pointer;
    margin-right:10px;
`;
export default Top;