import React, { useState } from "react";
import styled from "styled-components";
import { StatusEnum } from "../../utils/matchingTable";
import { SCardCaption } from "../Tab/styles/tab";
import { useNavigate } from "react-router-dom";
import { formatCntDay } from "../../utils/formatter";

const MyReservationThumnail = ({order}) => {
    const [recentOrder, setRecentOrder] = useState([]);
    const navigate = useNavigate();
    const move = () => {
        navigate('/hotels/'+ recentOrder.accommodation_id +'/' + recentOrder.room_id + '/reserve',{
            state:{
                order : recentOrder,
                hotel: recentOrder.accommodation_name,
                room:recentOrder.room_name
            }
        });
    }
    useState(()=>{
        if((order?.approved.length)){
            setRecentOrder(order?.approved[0]);
        }else if((order?.pending.length)){
            setRecentOrder(order?.pending[0]);
        }else if((order?.completed.length)){
            setRecentOrder(order?.completed[0]);
        }else {
            setRecentOrder(order?.cancelled[0]);
        }
    },[])
    return(
        <SThumnailBox>
            <SThumnailHeader>
                <div>
                    {recentOrder?.accommodation_name}
                </div>
                <SThumnailStatus>
                    {StatusEnum.get(recentOrder?.order_status)}
                </SThumnailStatus>
            </SThumnailHeader>
           <SThumnailBody>
                    <SCardCaption>
                        숙박 날짜
                    </SCardCaption>
                    <SCardCaption>
                        {recentOrder?.order_check_in} ~ {recentOrder?.order_check_out}({formatCntDay(recentOrder?.order_check_in, recentOrder?.order_check_out)}박)
                    </SCardCaption>
           </SThumnailBody>
           { recentOrder?.order_status === 'approved' ?
                <SThumnailFooter backgroundColor="#00B6F1" onClick={move}>
                        주문 번호 {recentOrder?.order_id} 예약하기 Click
                </SThumnailFooter>
                : <SThumnailFooter backgroundColor="#747474">
                     주문 번호 {recentOrder?.order_id}  {StatusEnum.get(recentOrder?.order_status)}
                </SThumnailFooter>    
        }
        </SThumnailBox>
    )
}
const SThumnailBox = styled.div`
    width:100%;
    border-radius:10px;
    min-height:100px;
    margin:10px 0px 20px 0px;
    border: 1px solid #c7c7c7;
`;
const SThumnailHeader = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 14px;
`;
const SThumnailBody = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size:12px;
`;
const SThumnailFooter = styled.div`
    padding:5px;
    background-color:${props => props.backgroundColor};
    line-height:24px;
    color:#fff;
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    cursor:pointer;
`;
const SThumnailStatus = styled.div`
    color:red;
`;
export default MyReservationThumnail;