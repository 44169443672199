import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from 'embla-carousel-react'
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { generatedformatCurrency } from "../../utils/generatedOrderId";

export const PrevButton = (props) => {
  const { children, ...restProps } = props

  return (
    <button
      className="embla__button embla__button--prev"
      type="button"
      {...restProps}
    >
      <svg className="embla__button__svg" viewBox="0 0 532 532">
        <path
          fill="currentColor"
          d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z"
        />
      </svg>
      {children}
    </button>
  )
}

export const NextButton = (props) => {
  const { children, ...restProps } = props

  return (
    <button
      className="embla__button embla__button--next"
      type="button"
      {...restProps}
    >
      <svg className="embla__button__svg" viewBox="0 0 532 532">
        <path
          fill="currentColor"
          d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"
        />
      </svg>
      {children}
    </button>
  )
}

const Carousel = (props) => {
  const { slides, options } = props

  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollTo = useCallback(
    (index) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  )
  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  )
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  )
  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return
    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onInit, onSelect])
  return (
    <>
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides?.map((index) => (
         
              <SCarouselSlide width={options.width} key={index.id}>
                <Link className="link" to= {options.param +'/'+index.id} onClick={(e)=>{
                  if(!(index.hasOwnProperty('hotel_photos'))){
                    e.preventDefault();
                  }
                }}>
                <SImageWrapper
                  className="embla__slide__img"
                  src={index.hasOwnProperty('hotel_photos') ?  index.hotel_photos[0] : index}
                  alt= {index.hotel_name +" 이미지"}
                  radius={options.radius}
                  height={options.height}
                />
                { options.body && 
                  <SCarouselBody>
                    <SCarouselCaption>
                      {index.hotel_region}

                    </SCarouselCaption>
                    <SCarouselTitle>
                      {index.hotel_name}
                    </SCarouselTitle>
                    <SPrice>
                      <SPriceDiscount>{index.rooms[0].discount_percent}</SPriceDiscount>{generatedformatCurrency(index.rooms[0].updated_price)}
                    </SPrice>
                  </SCarouselBody>
                }
                </Link>
              </SCarouselSlide>
            
          ))}
        </div>
      </div>
      { options.buttons &&     
        <div className="embla__buttons">
              <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} />
              <NextButton onClick={scrollNext} disabled={nextBtnDisabled} />
        </div>}
    
    </div>
   
    </>
    
  )
}
export default Carousel;
const SCarouselSlide = styled.div`
  flex: 0 0 calc(${(props) => (props.width && props.width)} - 1rem);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
`;
const SCarouselBody = styled.div`
  margin : 10px 0px;
  padding : 2px 2px;
  line-height:20px;
`;
const SCarouselTitle = styled.div`
  font-size:16px;
  font-weight:600;
`;
const SCarouselCaption = styled.div`
  font-size:14px;
  font-weight:400;
`;
const SImageWrapper = styled.img`
  border-radius: ${(props) => (props.radius && props.radius)};
  height:${(props) => (props.height && props.height)};
  object-fit: cover;
`;

const SPrice = styled.div`
  color: #333;
  font-size: 15px;
  font-weight: 700;
  // line-height: 130%; /* 15.6px */
  display:flex;
  gap : 0px 5px;
`

const SPriceDiscount = styled.div`
  font-size:11px;
  color:#00b6f1;
`