import styled from "styled-components";

export const SKnockGreyButton = styled.div`
    padding:10px;
    font-size:14px;
    background-color:#eee;
    border-radius:${props=>props.radius};
    width:${props=>props.width};
    font-weight:600;
    margin : auto;
    cursor:pointer;
    color:#333;
`