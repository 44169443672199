import React, { useEffect, useState } from "react";
import CheckBox from "../../assets/images/check_box.svg";
import Check from "../../assets/images/check.svg";
import Select from "../../assets/images/select.svg";
import Selected from "../../assets/images/selected.svg";
import styled from "styled-components";
const ReserveAgreement = ({hotel_name, start_date, end_date, all_agree}) =>{
    const [showDetails, setShowDetails] = useState({
        personalInfo: false,
        thirdParty: false,
        minor: false,
        refund: false,
    });
    const [selectedAgreement, setSelectAgreement] = useState({
        personalInfo: false,
        thirdParty: false,
        minor: false,
        refund: false,
    });
    const toggleDetail = (section) => {
        setShowDetails(prev => ({ ...prev, [section]: !prev[section] }));
    }
    const selectAgreement = (section) => {
        setSelectAgreement(prev => {
            const updatedState = { ...prev, [section]: !prev[section] };
            // 모든 약관이 체크되었는지 확인
            const allChecked = Object.values(updatedState).every(value => value === true);
            all_agree(allChecked);  // 상위 컴포넌트에 모든 약관 체크 상태 전달
            return updatedState;
        });
        
    }
    useEffect(()=>{
      
    },[])
    return (
        <SAgreementSection>
             <SAgreementContainer>
                {selectedAgreement.personalInfo ? <DynamicIcon src={Check} onClick={() => selectAgreement('personalInfo')}/> :  <DynamicIcon src={CheckBox} onClick={() => selectAgreement('personalInfo')}/>} 
                <div>[필수]개인정보 수집 및 이용</div>
                {showDetails.personalInfo ? <DynamicIcon src={Selected} onClick={() => toggleDetail('personalInfo')}/> :  <DynamicIcon src={Select} onClick={() => toggleDetail('personalInfo')}/>} 
            </SAgreementContainer>
            {showDetails.personalInfo && ( 
                <SAgreementWrapper>
                    <h2>개인정보 수집 및 이용</h2>
                    <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                    <table border="1">
                        <thead>
                            <tr>
                                <th>분류</th>
                                <th>수집・이용 동의 목적</th>
                                <th>수집・이용 항목</th>
                                <th>보유・이용기간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>필수</td>
                                <td>계약의 이행 및 서비스 제공,  예약・구매・관심상품 내역, 결제대금의 청구, 상담・불만・민원처리, 고지・안내사항 전달, 상품・서비스 이용실적 정보 통계・분석, 상품・서비스 개선 및 추천, 불법・부정이용 방지</td>
                                <td>일반 계정을 통한 서비스 이용시 ・ 아이디(이메일), 비밀번호(이메일 회원만 수집), 닉네임, 휴대폰 번호  SNS 계정을 통한 서비스 이용시 ・ 카카오계정(이메일), 카카오계정(전화번호), CI(연계정보) ・ 네이버 계정(이메일), 휴대전화 번호  예약・구매시 ・ 공통 - 예약내역(예약일시, 결제금액, 업체명) ・ 예약자 및 구매자 - 이름, 휴대폰 번호, (필요한 서비스의 경우)생년월일 ・ 예약자와 방문자가 다른 경우 - 방문자 및 탑승자의 이름, 휴대폰 번호, (필요한 서비스의 경우)생년월일 ・ 예약확인서 발급 시 - 예약자의 이메일 주소 현금영수증 발급시  ・휴대전화번호, 이메일  현금 환불 요청 시 ・ 계좌번호 및 예금주명  고객상담 시 ・ 상담내용 및 상담에 필요한 개인정보</td>
                                <td>회원 탈퇴 요청 시 상담 등 민원처리, 정산 및 환불 처리를 위해 30일 후 삭제</td>
                            </tr>
                        </tbody>
                    </table>
                </SAgreementWrapper>
            )}
             <SAgreementContainer>
                {selectedAgreement.thirdParty ? <DynamicIcon src={Check} onClick={() => selectAgreement('thirdParty')}/> :  <DynamicIcon src={CheckBox} onClick={() => selectAgreement('thirdParty')}/>} 
                <div>[필수]개인정보의 제3자 제공</div>
                {showDetails.thirdParty ? <DynamicIcon src={Selected} onClick={() => toggleDetail('thirdParty')}/> :  <DynamicIcon src={Select} onClick={() => toggleDetail('thirdParty')}/>} 
            </SAgreementContainer>
            {showDetails.thirdParty && ( 
                <SAgreementWrapper>   
                    <h2>개인정보의 제3자 제공</h2>
                    <p>회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.</p>

                    <p>회사는 원할한 서비스 제공을 위하여 다음의 경우 이용자의 동의를 얻어 개인정보를 제공하고 있습니다.</p>
                    <table border="1">
                        <thead>
                            <tr>
                                <th>제공받는 자</th>
                                <th>제공 목적</th>
                                <th>제공 항목</th>
                                <th>보유 및 이용기간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{hotel_name}</td>
                                <td>숙박예약서비스 이용계약 이행(서비스 제공확인 / 이용자 정보 확인)</td>
                                <td>예약번호, 예약자명,예약자 휴대폰 번호</td>
                                <td>예약서비스 제공 완료 후 6개월</td>
                            </tr>
                        </tbody>
                    </table>
                </SAgreementWrapper>
            )}
            <SAgreementContainer>
                {selectedAgreement.minor ? <DynamicIcon src={Check} onClick={() => selectAgreement('minor')}/> :  <DynamicIcon src={CheckBox} onClick={() => selectAgreement('minor')}/>} 
                <div>[필수]미성년자(청소년) 투숙 기준 동의</div>
                {showDetails.minor ? <DynamicIcon src={Selected} onClick={() => toggleDetail('minor')}/> :  <DynamicIcon src={Select} onClick={() => toggleDetail('minor')}/>} 
            </SAgreementContainer>
            {showDetails.minor && ( 
                <SAgreementWrapper>
                    <h2>미성년자(청소년) 투숙 기준 동의</h2>
                     <li> 만 19세 미만 미성년자(청소년)의 경우 예약 및 투숙이 불가합니다.</li>
                     <li> 만 19세 미만 미성년자(청소년)가 투숙을 원하는 경우 보호자(법정대리인)가 필수 동행해야 합니다.</li>
                     <li> 이용일 당일 미성년자(청소년) 투숙 기준 위반이 확인되는 경우 환불없이 퇴실 조치됩니다.</li>                 
                </SAgreementWrapper>
            )}
            <SAgreementContainer>
                {selectedAgreement.refund ? <DynamicIcon src={Check} onClick={() => selectAgreement('refund')}/> :  <DynamicIcon src={CheckBox} onClick={() => selectAgreement('refund')}/>} 
                <div>[필수]환불 규정에 대한 동의</div>
                {showDetails.refund ? <DynamicIcon src={Selected} onClick={() => toggleDetail('refund')}/> :  <DynamicIcon src={Select} onClick={() => toggleDetail('refund')}/>} 
            </SAgreementContainer>
            {showDetails.refund && ( 
                <div>
                    <SAgreementWrapper>
                        <h2>숙소 취소 및 환불 규칙</h2>
                        <div>
                        <table border="1">
                            <thead>
                                <tr>
                                    <th>기간</th>
                                    <th>취소수수료율</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> 입실 1일 전 18:00 전 까지</td>
                                    <td>총 판매가의 0% (무료취소)</td>
                                </tr>
                                <tr>
                                    <td> 입실 1일 전 18:00 ~ 당일 00:00 전 까지</td>
                                    <td>총 판매가의 100% (1박요금 100% 부과)</td>
                                </tr>
                                <tr>
                                    <td>입실 당일 취소 및 NO-show, 입실 시간 경과/실제 입실 후</td>
                                    <td>취소 및 환불 불가</td>
                                </tr>
                            </tbody>
                        </table>
                            <div>
                                                
                            <h4>취소 불가 사항</h4>
                                다음의 경우, 예약취소 및 결제금액 환불이 불가능합니다.
                                <li>입실시간 경과(No-Show 포함) 또는 실제 입실이 발생한 경우 취소 및 환불이 불가합니다.</li>
                                <li>청소년 보호법에 따라 미성년자(만 19세 미만 청소년)의 경우 혼숙이 금지되며, 법정대리인 동행 없이 혼숙이 불가합니다.</li>
                                <li>취소 불가 시점에 주차불가 사유로 인한 취소처리는 불가합니다.</li>
                                <li>해당 사유로 인하여 현장에서 입실이 불가할 경우, 취소 및 환불이 불가합니다.</li>
                            </div>
                        </div>
                    </SAgreementWrapper>
                </div>
            )}
            
            
        </SAgreementSection>
    )
}
const SAgreementSection = styled.section`
    display:flex;
    flex-direction:column;
    gap:5px 0px;
    margin:20px 10px;
`
const SAgreementContainer = styled.div`
    display:flex;
    align-item:center;
    color: #4e5968;
`;
const SAgreementWrapper = styled.div`
    background-color: #f1f1f1;
    padding: 20px;
    margin: 10px 0px;
`
const DynamicIcon = styled.img`
    width:18px;
    height:18px;
    margin: 0px 10px 0px 0px;
`;
export default ReserveAgreement;