import React from "react";
import { styled } from "styled-components";
import CarouselWrapper from "../Carousel/CarouselWrapper";
import { useNavigate } from "react-router-dom";
import { generatedformatCurrency } from "../../utils/generatedOrderId";
const HotelCard = (props) =>{
    console.log(props);
    const hotelInfo = props.hotel;
    const navigate = useNavigate();
    const moveDetail = (hotel) => {
        navigate('/hotels/'+ props.hotel.id);
    }
    return(
        <SCardWrapper onClick={moveDetail}>
            <SCardTopSection>
                <SRoomTopLeft>
                    <CarouselWrapper param='hotels' slides={hotelInfo.hotel_photos} width='100%' height='150px' radius="10px 10px 0px 0px" body={false}></CarouselWrapper>
                </SRoomTopLeft>
                
            </SCardTopSection>
            <SRoomBodySection>
                    <SRoomTitle>
                        {hotelInfo.hotel_name}
                        {hotelInfo.haveWheelChairRoom && <SWheelRoomTag>장애인 객실</SWheelRoomTag>}
                        {hotelInfo.haveWheelChairRoom || <SRoomTag>호캉스</SRoomTag>}
                    </SRoomTitle>
                    <SRoomCaptionWrapper>
                        <SRoomCaption>
                            {hotelInfo.hotel_address}
                        </SRoomCaption>
                        <SRoomBody>
                            {generatedformatCurrency(hotelInfo.rooms[0].updated_price)}
                        </SRoomBody>
                    </SRoomCaptionWrapper>
                </SRoomBodySection>
            {/* <SRoomDetailButoon >
                객실 정보 상세<IconBox src={NextIcon}/>
            </SRoomDetailButoon> */}
    
        </SCardWrapper>
    )
}

const SCardWrapper = styled.div`
    background-color:white;
    border-radius:10px;
    border : 1px solid #c9c9c9;
    padding:0px 0px 0px 0px;
    margin: 20px 20px;
    cursor:pointer;
`;
const SCardTopSection = styled.div`
    display:flex;
`;
const SRoomTopLeft = styled.div`
    width:100%;
    display: flex;
    align-items: center;
`;
const SRoomBodySection = styled.div`
    display:flex;
    flex-direction:column;
    padding:15px;
    justify-content: space-around;
`;
const SRoomTitle = styled.div`
    font-size:18px;
    font-weight:800;
    display:flex;
    justify-content:space-between;
`;
const SRoomCaptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px 0px;
    margin:0px 0px 0px 0px;
`
const SRoomCaption = styled.div`
    color:#999999;
    font-size:12px;
    font-weight:600;
`;
const SRoomBody = styled.div`
    font-size:20px;
    font-weight:800;
`
const SWheelRoomTag = styled.div`
    font-size: 14px;
    font-weight: 600;
    background-color: #00B6F1;
    padding: 5px 10px;
    border-radius: 30px;
    color: #ffffff;
`;
const SRoomTag = styled.div`
    font-size: 14px;
    font-weight: 600;
    background-color: #c7c7c7;
    padding: 5px 10px;
    border-radius: 30px;
    color: #ffffff;
`
export default HotelCard;