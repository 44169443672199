import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../../components/Logo/Logo";
import { firebaseAuth } from "../../config/firebase";
import { deleteRefreshToken } from "../../utils/token";
import { clearAccessToken, setAccessToken } from "../../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MyReservationThumnail from "../../components/Card/MyReservationCard";
import { getMyInfo } from "../../utils/api";
import { refreshToken } from "../../utils/common";
import Modal from "../../components/Modal/Modal";
import { SKnockGreyButton } from "../../styles/global";
import { Snackbar } from "@mui/material";

const MyPage = () =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [order, setOrder] = useState();
    const [user, setUser] = useState("ananymos");
    const [isModal, setIsModal] = useState(false);
    const accessToken = useSelector(state=> state.auth.accessToken);
    
    const deleteAccessToken = () => {
        dispatch(clearAccessToken());
    }

    const logout = () => {
        deleteRefreshToken();
        deleteAccessToken();
        firebaseAuth.signOut();
        navigate('/login');
    }

    useEffect(()=>{
        const fetchMe = async () =>{
            try {
                const data = await getMyInfo(accessToken);
                if(data.ret) {
                    setUser(data.ret);
                    setOrder(data.ret.orders);
                }
                else {
                    /* 로그 코드*/
                    console.log("다음과 같은 에러가 있습니다. " + data.error_code);
                    
                    switch(data.error_code) {
                        case 'AUTH_TOKEN_EXPIRED':
                            console.log('토큰 만기로 토큰을 refresh 하겠습니다. ');
                            dispatch(setAccessToken(await refreshToken()));
                            break;
                        case 'AUTH_CREDENTIALS_INVALID':
                            console.log('적절하지 않은 토큰(null)으로 토큰을 refresh 하겠습니다. ');
                            dispatch(setAccessToken(await refreshToken()));
                            break;
                      }       
                }
            } catch (error) {
                console.log("예상치 못한 에러입니다.");
                console.error(error);
            }
        }
        fetchMe();
    },[accessToken])
    return(
        <div id="child">
            <div>
                <SLogoSection>
                    <Logo></Logo>
                    {/* <KakaoChannel></KakaoChannel> */}
                </SLogoSection>
                <SMyPageSection>
                    <SMyPageTopContainer>
                        <SMyPageRow className="link" to='/mypage/myProfile'>
                            {/* <SImgIcon src={ProfileIcon}></SImgIcon>
                            {user} 님의 마이페이지 */}
                            {user?.user_email && user?.user_email + ' 님'} 프로필
                            <i className="fa-solid fa-chevron-right"></i>
                        </SMyPageRow>
                    </SMyPageTopContainer>
                    <SMyPageTopContainer>
                        <SMyPageRow className="link" to='/mypage/myReservation'>
                           내 예약 정보
                            <i className="fa-solid fa-chevron-right"></i>
                        </SMyPageRow>
                       
                    </SMyPageTopContainer>
                    <SMyPageTopContainer>
                        <SMyPageRow to="/mypage/myCoupon" className="link">
                            쿠폰함
                            <i className="fa-solid fa-chevron-right"></i>
                        </SMyPageRow>
                    </SMyPageTopContainer>
                    <SMyPageTopContainer>
                        <SMyPageRow className="link" to="/mypage/myBookmark">
                            북마크
                            <i className="fa-solid fa-chevron-right"></i>
                        </SMyPageRow>
                    </SMyPageTopContainer>
                    
                    <div>
                        <SMyPageTitle>
                                최근 예약
                        </SMyPageTitle>
                        {(order?.pending !== undefined) || (order?.approved !== undefined) ? <MyReservationThumnail order={order}></MyReservationThumnail> : <div>최근 예약 내역이 존재하지 않습니다.</div>}
                        <SMyPageTitle>
                            고객센터
                        </SMyPageTitle>
                        <SMyPageTopContainer>
                            <a className='link' href="javascript:chatChannel()">카카오톡 1:1문의</a>
                            <a className="link" href="https://baithings.co.kr">공지사항</a>
                            <div onClick={()=>setIsModal(true)}>로그아웃</div>
                        </SMyPageTopContainer>

                    </div>
                </SMyPageSection>
                <Modal 
                    isOpen={isModal}
                    height="100vh"
                    backgroundColor="rgb(0 0 0 / 68%);"
                    justifyContent="center">

                    {/* 모달 내용을 감싸는 박스 */}
                    <div className="modal-box">

                        {/* 모달 닫기 버튼 */}
                        <div className="modal-box-close">
                        <i onClick={() => setIsModal(false)} className="fa-solid fa-xmark"></i>
                        </div>
                            로그아웃 하시겠습니까?
                            <div/>

                        
                            <SKnockGreyButton radius="30px" width="100px" onClick={logout}>로그아웃</SKnockGreyButton>
                            {/* 자식 컴포넌트를 모달 박스 내에 렌더링 */}
                    </div>
                    </Modal>
            </div>
        </div>
    )
}

const SLogoSection = styled.div`
    padding: 10px 0px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content:  space-between;
`;
const SMyPageSection = styled.section`
    padding:20px;
    display: flex;
    flex-direction: column;
    gap: 20px 0px;
`;
const SImgIcon = styled.img`
    width:20px;
`;
const SMyPageTopContainer = styled.div`
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    padding: 20px;
    color: #333;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    display: flex;
    flex-direction:column;
    gap: 10px 0px;
`;
const SMyPageTitle = styled.div`
    font-size:18px;
    font-weight:700;
    margin:10px 0px;
`
const SMyPageRow =styled(Link)`
    display: flex;
    gap: 0px 10px;
    font-size: 16px;
    font-weight: 400;
    color: #7e7e7e;
    justify-content: space-between;
    align-items: center;    
`;
export default MyPage;