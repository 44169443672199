import React, { useState } from "react";

const Tabs =({tabs}) =>{
    const [activeTab, setActiveTab] = useState(0);

    return(
        <div className="tabs">
            <div className="tab-labels">
                {tabs.map((tab, index)=>(
                    <div  
                        key={index}
                        className={`tab-label ${index === activeTab ? 'active' : ''}`}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab.label}
                    </div>
                ))}
            </div>
            <div className="tab-content">
                {tabs[activeTab].content}
            </div>
            <div className="knock-bottom2"></div>
        </div>
    )
}

export default Tabs;