import { style } from "@mui/system";
import styled from "styled-components";

export const SModalBody = styled.div`
    padding:20px;
`;
export const SModalTitle = styled.div`
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 600;
`;
export const SSectionTitle = styled.div`
    display:flex;
    justify-content: space-between;
    font-weight:800;
    margin:20px 0px;
    font-size:18px;
`;
export const STopBox = styled.div`
    display:flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    justify-content: flex-end;
    padding: 0px 10px;
`;
export const SImgWrapper = styled.img`
    width:20px;
    cursor:pointer;
    margin: 10px 5px;
`;
export const SAlertMsg = styled.div`
    text-align: center;
    color: red;
    font-size: 12px;
`;
export const SUserInfo = styled.div`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;    
`;



export const SInfoWrapper = styled.div`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;    
`;

export const SInfoTitle = styled.div`
    font-size:20px;
    margin:10px;
    font-weight:600;
    color:#333;
`;

export const SInfoFlex = styled.div`
    display:flex;
    justify-content:space-between;
    margin:10px;
    flex-wrap: wrap;
    gap: 10px;
}
`;
export const SInfoFlexTwo = styled.div`
    display:flex;
    gap:10px;
    align-items:center;
`;
export const SInfoLabel = styled.div`
    color:#878787;
    font-size:14px
    font-weight:400;

`;
export const SInfoCaption = styled.div`
    color:#333;
    font-weight:600;
`;

export const SInfoAlert = styled.div`
    margin:10px;
    font-size:12px;
    font-weight:700;
    color:red;
`;

/* MyReservationCoupon.jsx */
export const SCouponWrapper = styled.div`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;    
`;
export const SFlex = styled.div`
    display:flex;
    justify-content:space-between;
    margin:10px;
`;


/* ReservationPaymentSection.jsx */

export const STotalCost = styled.div`
    font-size:20px;
    color:#f04452;
    font-weight:800;
`