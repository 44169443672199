import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
    const navigate = useNavigate();
    const move = () =>{
        navigate('/');
    }
    return(
    <div id="child">
        <div className="redirect-container">
            결제 성공하였습니다. 
            <div className="button-cta redirect-button" onClick={()=> move()}>확인</div>
        </div>
    </div>
    )
}
export default SuccessPage;