import React from "react";
import styled from "styled-components";
import CarouselWrapper from "../Carousel/CarouselWrapper";

const Card = (props) => {
  const renderCarousel = (photos) => {
    if (photos && photos.length > 0) {
      return (
        <CarouselWrapper param='hotels' slides={photos} width='100%' radius="0px" body={false} buttons={true}></CarouselWrapper>
      );
    }
    return null;
  };

  return (
    <>
    <SH3>
      <h3 id={props.props_id}>
          {props.title === '현관' && <><i class="fa-solid fa-door-open i-title"/>현관</>}
          {props.title === '화장실' && <><i class="fa-solid fa-shower i-title"/>화장실</>}
          {props.title === '침실' && <><i class="fa-solid fa-bed i-title"/>침실</>}
      </h3>
    </SH3>
     
      {props.title === '현관' && renderCarousel(props.roomInfo.room_wheelchair_photos.room_entrance)}
      {props.title === '화장실' && renderCarousel(props.roomInfo.room_wheelchair_photos.room_restroom)}
      {props.title === '침실' && renderCarousel(props.roomInfo.room_wheelchair_photos.room_bedroom)}
        <SWheelInfoWrapper>
          {props.e_distance >= 0 ? <SWheelInfo><i class="fa-solid fa-arrows-left-right i-icon"></i>길이 <SBold>{props.e_distance}cm</SBold></SWheelInfo> : null}
          {props.e_height >= 0 ? <SWheelInfo><i class="fa-solid fa-caret-up i-icon"></i> 단차<SBold>{props.e_height}cm</SBold></SWheelInfo>: null}
        </SWheelInfoWrapper>
        <SWheelInfoWrapper>
          {props.r_width >= 0 ? <SWheelInfo><i class="fa-solid fa-ruler-horizontal"></i> 문폭 <SBold>{props.r_width}cm</SBold></SWheelInfo>: null}
          {props.r_height >= 0 ? <SWheelInfo><i class="fa-solid fa-caret-up i-icon"></i> 단차<SBold>{props.r_height}cm</SBold></SWheelInfo>: null}
          {props.r_bathhub > 0 ? <SWheelInfo><i class="fa-solid fa-bath i-icon"></i><SBold>욕조형</SBold></SWheelInfo> : null}
          {props.r_toilet ? <SWheelInfo><i class="fa-solid fa-toilet i-icon"></i><SBold>{props.r_toilet}</SBold></SWheelInfo> : null}
        </SWheelInfoWrapper>
        <SWheelInfoWrapper>
          {props.b_height >= 0 ? <SWheelInfo><i class="fa-solid fa-arrows-up-down i-icon"></i> 높이 <SBold>{props.b_height}cm</SBold></SWheelInfo>: null}
          {props.b_space >= 0 ? <SWheelInfo><i class="fa-solid fa-vector-square i-icon"></i> 공간<SBold>{props.b_space}cm</SBold></SWheelInfo>: null}
        </SWheelInfoWrapper>
    </>
  );
};

export default Card;

const SBold = styled.div`
  font-weight: 800;
`;

const SWheelInfoWrapper = styled.div`
  margin:10px 0px;
  display: flex;
  gap: 0px 10px;
  flex-wrap: wrap;
`;
const SWheelInfo = styled.div`
  line-height:24px;
  margin: 5px 0px;
  width: 120px;
  padding: 3px 0px;
  font-size: 14px;
  display: flex;
  gap: 0px 5px;
  justify-content: center;
  align-items: center;
  border-radius:30px;
  // background-color:#00b6f1;
  color:#777777;
  border:1px solid #777777;
`;

const SH3 = styled.div`
  margin:20px 0px;
  padding: 5px 0px;
  border-bottom: 2px solid #eee;
`