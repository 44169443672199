/* MyBookmarkCard.jsx */

import { Link } from "react-router-dom";
import styled from "styled-components";

export const SCardContainer = styled.div`
    width:85%;
    background-color:#fff;
    border-radius:20px 20px 20px 20px;
`
export const SCardHeader= styled.div`
    padding:20px 20px 10px 20px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-bottom:1px solid #f1f1f1;
`;
export const SCardBody = styled.div`
    display:flex;
    align-items: center;
`;
export const SCardBodyLeft = styled.div`
    width:30%;
`;
export const SCardBodyImgWrapper = styled.div`
    padding:20px;
    width:100px;
    height:100px;
`;
export const SCardBodyImg = styled.img`
    width:100%;
    height:100%;
    background-color:#eee;
`
export const SCardBodyRight = styled.div`
    width:70%;
`;
export const SCardBodyTop = styled.div`
    padding:20px 20px 10px 20px;
    font-size:24px;
    font-weight:800;
`;

export const SCardBodyBottom = styled.div`
    padding:0px 20px 20px 20px;
    color:#ccc;
`;

export const SCardFooter = styled.div`
    background-color:#ccc;
    padding:10px 20px;
    border-radius:0px 0px 20px 20px;
    font-size:12px;
    font-weight:600;
    text-align:right;
    display: flex;
    justify-content: space-between;
`;

export const SCardHeaderLeft = styled.div`
    font-size:18px;
    font-weight:900;
    color:#333;
`;

export const SCardHeaderRight = styled(Link)`
    font-size:12px;
`;