import React from 'react';
import './App.css';
import './styles/global.css';
import './styles/fonts.css';
import './styles/input.css';
import './styles/keyframe.css';
import './styles/modal.css';
import { Route, Routes } from 'react-router-dom';
import MainPage from './pages/Main/MainPage';
import SearchPage from './pages/SearchPage';
import AccommodationPage from './pages/AccommodationPage';
import RoomDetailPage from './pages/RoomDetailPage';
import TermsPage from './pages/TermsPage';
import PolicyPage from './pages/PolicyPage';
import Test from './pages/Test';
import LoginPage from './pages/Login/LoginPage';
import MyPage from './pages/MyPage/MyPage';
import SignUpPage from './pages/SignUp/SignUpPage';
import ReservationPage from './pages/Reservation/ReservationPage';
import MyReservationPage from './pages/Reservation/MyReservationPage';
import MyReservationDetailPage from './pages/Reservation/MyReservationDetailPage';
import SuccessPage from './pages/SuccessPage';
import FailurePage from './pages/FailurePage';
import MyCouponPage from './pages/Coupon/MyCouponPage';
import RegisterCoupon from './pages/Coupon/RegisterCoupon';
import MyProfilePage from './pages/Profile/MyProfilePage';
import MyBookmarkPage from './pages/Bookmark/MyBookmarkPage';

function App() {
  return (
 
  <>
     <Routes>
        <Route path="/" element={<MainPage/>} />
        <Route path="/main" element={<MainPage/>} />
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/signup" element={<SignUpPage/>} />
        <Route path="/mypage" element={<MyPage/>} />
        <Route path='/mypage/myProfile' element={<MyProfilePage/>} />
        <Route path='/mypage/myBookmark' element={<MyBookmarkPage/>} />
        <Route path="/mypage/myCoupon" element={<MyCouponPage/>}/>
        <Route path='/mypage/myCoupon/register' element={<RegisterCoupon/>}/>
        <Route path="/terms" element={<TermsPage/>}/>
        <Route path="/policy" element={<PolicyPage/>}/>
        <Route path="/test" element={<Test/>}/>
        <Route path="/search" element={<SearchPage/>} />
        <Route path='/mypage/myReservation' element={<MyReservationPage/>} />
        <Route path='/mypage/myReservation/detail' element={<MyReservationDetailPage/>} />
        <Route path="/hotels/:accommodationId" element = {<AccommodationPage/>} />
        <Route path="/hotels/:accommodationId/:room" element={<RoomDetailPage/>} />
        <Route path="/hotels/:accommodationId/:roomId/reserve" element={<ReservationPage/>} />
        <Route path="/reserve/success" element={<SuccessPage/>} />
        <Route path='/reserve/failure' element={<FailurePage/>} />
    </Routes>
  </>
  );
}

export default App;
