import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Top from "../../components/Header/Top";
import styled from "styled-components";
import MyCouponCard from "./MyBookmarkCard";
import MyBookmarkCard from "./MyBookmarkCard";

const MyBookmarkPage = () =>{
    const [bookmarks, setBookmarks] = useState([]);
    const accessToken = useSelector(state=> state.auth.accessToken);
    useEffect(()=>{
        try {
            
        } catch (error) {
            
        }
    },[accessToken])
    return(
        <div id="child">
            <div className="top-section">
                <Top title="북마크"></Top>
            </div>
            <SCouponSection>
                <SBodyContent>
                    {bookmarks?.map((bookmark)=>(
                        <MyBookmarkCard bookmark={bookmark}></MyBookmarkCard> 
                    ))}
                </SBodyContent>
            </SCouponSection>
        </div>
    )
}

export const SCouponSection = styled.div`
    height:100%;
`;

export const SBodyTitle = styled.div`
    display:flex;
    justify-content: space-between;
    padding:20px;
    font-weight:600;
`;
export const SBodyContent = styled.div`
    background-color:#F2F5F7;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px 0px;
    overflow:auto;
`
export default MyBookmarkPage;