import React, { useEffect, useState } from "react";
import LogoImg from "../../assets/main-logo.png";
import { Link, useNavigate } from "react-router-dom";
import CheckBox from "../../assets/images/check_box.svg";
import Check from "../../assets/images/check.svg";
import { DynamicIcon, EssentialCode, SButton, SInput, SInputCaption, SInputContainer, SLoginLink, SLogoImg, SRegisterSection, TermMagCaption, TermMsg } from "./styles";
import { healthCheck, signUpEmail } from "../../utils/api";
import { checkEmail, checkPw } from "../../utils/common";
import { useQuery } from "react-query";
import LoadingScreen from "../../components/Common/Loading";

const Register = () =>{
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState("");
    const [isCheck, setIsCheck] = useState({
        adultCheck : false,
        serviceCheck : false,
        privacyCheck : false
    });
    const [isToggle , setIsToggle] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const register = async () => {
      setIsLoading(true);

        try {
          signUpEmail(registerEmail, registerPassword)
          .then((res)=>{
            if(res.detail) {
              throw res;
            }
          })
          .then(()=>{
            // alert("가입하신 이메일로 인증 메일을 발송하였습니다. 인증 후 로그인이 가능합니다.");
            navigate('/login', {state: {snackBar:true}});
            setIsLoading(false);

          })
          .catch((error)=>{
            console.log(error);
            switch(error.error_code){
                case "AUTH_PASSWORD_NOT_VERIFIED":
                  setErrorMsg("패스워드는 대문자, 소문자, 숫자, 특수문자를 \n 최소 1개 이상 입력해주세요.");
                  break;
                default : 
                  setErrorMsg(error.detail);
              }
            setIsLoading(false);
          })
        } catch (error) {
          console.log(error);
          setErrorMsg(error.detail);
          setIsLoading(false);
        }
    }
    const toggleIsCheck = (check) => {
        switch (check) {
          case 'adult':
            setIsCheck({
              ...isCheck,
              adultCheck: !isCheck.adultCheck
          })
          break;
          case 'service':
            setIsCheck({
              ...isCheck,
              serviceCheck: !isCheck.serviceCheck
          })      
          break;
          case 'privacy':
            setIsCheck({
              ...isCheck,
              privacyCheck: !isCheck.privacyCheck
          })    
          break;
          case 'all':
            if(isToggle){
              setIsCheck({
                adultCheck : !isCheck.adultCheck,
                serviceCheck : !isCheck.serviceCheck,
                privacyCheck : !isCheck.privacyCheck
            })
            } else setIsToggle(!isToggle);
          break;
          default:
            alert('에러가 발생했습니다.');
        }
      }
    const handleShowRegister = () =>{
        if(checkEmail(registerEmail) !== '') setErrorMsg(checkEmail(registerEmail));
        else if(checkPw(registerPassword, registerPasswordConfirm) !== '') setErrorMsg(checkPw(registerPassword, registerPasswordConfirm));
        else if(Object.values(isCheck).includes(false)) setErrorMsg('이용 동의서에 동의하지 않으셨습니다.')
        else {
          setErrorMsg('');
          register();
        }
      }
      const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
          handleShowRegister()
        }
      };
    useEffect(()=>{
      healthCheck();
    },[])
    return(
        <div id="child">
          {isLoading ? <LoadingScreen></LoadingScreen> :
            <SRegisterSection>
                <SLogoImg src={LogoImg}></SLogoImg>
                <SInputContainer>
                    {/* <SInput placeholder="이름을 입력해주세요. ex.홍길동"></SInput> */}
                    <SInput 
                        placeholder="이메일을 입력해주세요. ex.knock@knock.co.kr"
                        value={registerEmail}
                        onChange={(e)=>{
                            setRegisterEmail(e.target.value);
                        }}></SInput>
                    <SInput 
                        type="password" 
                        placeholder="대문자, 소문자, 숫자, 특수문자를 최소 1개 이상 입력해주세요."
                        value={registerPassword}
                        onChange={(e)=>{
                            setRegisterPassword(e.target.value);
                        }}></SInput>
                    <div>
                        <SInput 
                            type="password" 
                            placeholder="비밀번호를 한 번 더 입력해주세요."
                            value={registerPasswordConfirm}
                            onChange={(e)=>{
                                setRegisterPasswordConfirm(e.target.value);
                            }}
                            onKeyDown={handleOnKeyPress}
                            name="password"></SInput>
                        {errorMsg ? <SInputCaption>{errorMsg}</SInputCaption> : null}
                    </div>
                    <TermMsg>
                    <TermMagCaption  onClick={()=>{toggleIsCheck('all')}}> 
                    {!(isCheck.adultCheck && isCheck.serviceCheck && isCheck.privacyCheck) ? 
                    <DynamicIcon src={CheckBox}/> 
                    : <DynamicIcon src={Check}/>}주식회사 베이띵스의 가입약관에 모두 동의합니다.</TermMagCaption>
                        {
                        isToggle ? 
                        <div>
                        <TermMagCaption onClick={()=>{toggleIsCheck('adult')}}>
                            {!isCheck.adultCheck ? <DynamicIcon src={CheckBox}/> 
                            : <DynamicIcon src={Check}/>}
                            만 14세 이상입니다. <EssentialCode>(필수)</EssentialCode>
                        </TermMagCaption>  
                        <TermMagCaption onClick={()=>{toggleIsCheck('service')}}>
                            {!isCheck.serviceCheck ? <DynamicIcon src={CheckBox}/> 
                            : <DynamicIcon src={Check}/>}
                           서비스   <Link to="/terms">이용 약관 </Link>에 동의합니다.<EssentialCode>(필수)</EssentialCode>
                        </TermMagCaption>  
                        <TermMagCaption onClick={()=>{toggleIsCheck('privacy')}}>
                            {!isCheck.privacyCheck ? <DynamicIcon src={CheckBox}/> 
                            : <DynamicIcon src={Check}/>}
                            개인정보 <Link to="/policy">수집/이용</Link>에 동의합니다.<EssentialCode>(필수)</EssentialCode>
                        </TermMagCaption>  
                        </div> : null}
                    </TermMsg>

                    <SButton onClick={()=>handleShowRegister()}>회원가입</SButton>
                    <SLoginLink to="/login">로그인 하러가기</SLoginLink>
                </SInputContainer>
            </SRegisterSection>
          }
        </div>
    )
}

export default Register;