import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CarouselWrapper from "../components/Carousel/CarouselWrapper";
import { styled } from "styled-components";
import WheelChairIcon from "../assets/images/wheelchair_icon.svg";
import PersonIcon from "../assets/images/person_icon.svg";
import TypeIcon from "../assets/images/type_icon.svg";
import CheckInIcon from "../assets/images/checkin_icon.svg";
import Modal from "../components/Modal/Modal";
import CloseIcon from "../assets/images/close_icon_white.png";
import Top from "../components/Header/Top";
import Card from "../components/Card/Card";


const RoomDetail = () =>{
    const [modalOpen, setModalOpen] = useState(false);
    const [clickedButton, setClickedButton] = useState("entrance");
    const [isVisible, setIsVisible] = useState(false);
    const loc = useLocation();
    const roomInfo = loc.state.room;
    const params = useParams();
    // 스크롤 이벤트 핸들러

    const handleScroll = () => {
        const scrollTop = document.getElementById("child").scrollTop;
        // console.log("child div의 현재 스크롤 위치:", scrollTop);
        if (scrollTop > 100) {
        setIsVisible(true);
        } else {
        setIsVisible(false);
        }
    };

    // 페이지 상단으로 스크롤하는 함수
    const scrollToTop = () => {
        document.getElementById("child").scrollTo({
        top: 0,
        behavior: "smooth",
        });
    };
    function handleButtonClick(buttonId) {
        // 버튼을 클릭할 때 해당 버튼의 ID를 상태에 저장
        setClickedButton(buttonId);
    }
    function scrollToElement(elementId) {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    
    useEffect(() => {
        // 스크롤 이벤트를 추가
        const childDiv = document.getElementById("child");
        const navDiv = document.querySelector('.nav-box');
        navDiv.style.display="none";
        childDiv.addEventListener("scroll", handleScroll);
        return () => {
          // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
          navDiv.style.display="flex";

          childDiv.removeEventListener("scroll", handleScroll);
        };
      }, []);
    return(
        <div id="child">
            <div className="top-section">
                <Top title={loc.state.hotel.hotel_name}></Top>
            </div>
            <div>
                {/* <RoomPhoto photos={roomInfo.room_photos}/> */}
                <CarouselWrapper param='hotels' slides={loc.state.room.room_photos} width='100%' radius="0px" body={false} buttons={true}></CarouselWrapper>
            </div>
            <div className="knock-title-section">
            <STitleBox>
                <STitle>
                    {roomInfo?.title}
                    {   roomInfo?.room_3d &&   
                            <STitle3DButton  
                            onClick={() => 
                                {
                                    setModalOpen(true);
                                }}>
                                3D 뷰어   
                                <div>
                                    <i class="fa-solid fa-cube fa-xs" style={{ color: "#00b6f1"}}></i>
                                </div>
                            </STitle3DButton>
                    }
                  
                </STitle>

                <STitleCaption>
                    <STitleInfoCaption>{roomInfo?.sub_title}</STitleInfoCaption>
                </STitleCaption>
            </STitleBox>
                
            <SRoomItemWrapper>
                <SRoomItem>
                    <SRoomInfoIcon src={PersonIcon}/>
                    <div>{roomInfo?.item.count_person}</div>
                </SRoomItem> |
                <SRoomItem>
                    <SRoomInfoIcon src={TypeIcon}/>
                    <div>{roomInfo?.item.bed_type}</div>
                </SRoomItem> |
                <SRoomItem>
                    <SRoomInfoIcon src={CheckInIcon}/>
                    <div>체크인 {roomInfo?.item.check_in}시<br/> 체크아웃 {roomInfo.item.check_out}시</div>
                </SRoomItem>
            </SRoomItemWrapper>
            </div>
            <div className="knock-body-section">
                <SBodyTitle><img className="knock-img-icon" src={WheelChairIcon}></img>휠체어 접근 정보</SBodyTitle>
                <SBodyContent>
                    <div className="button-wrapper">
                        <div
                        className={`button-tap ${clickedButton === "entrance" ? "clicked" : ""}`}
                        onClick={() => {
                                handleButtonClick("entrance");
                                scrollToElement("entrance");
                        }}
                        >
                          현관
                        </div>
                        <div
                        className={`button-tap ${clickedButton === "restroom" ? "clicked" : ""}`}
                        onClick={() => {
                            handleButtonClick("restroom");
                            scrollToElement("restroom");
                        }}
                        >
                            화장실
                        </div>
                        <div
                        className={`button-tap ${clickedButton === "bedroom" ? "clicked" : ""}`}
                        onClick={() => {
                            handleButtonClick("bedroom");
                            scrollToElement("bedroom");
                        }}
                        >
                          침실
                        </div>
                    </div>
                    {/* <Tabs tabs={tabData}/> */}
                    <Card title="현관" 
                        roomInfo={roomInfo || null} 
                        e_distance={roomInfo?.wheelchair_info.entrance_distance} 
                        e_height={roomInfo?.wheelchair_info.entrance_height} 
                        props_id="entrance"></Card>
                    <Card title="화장실" 
                        roomInfo={roomInfo || null} 
                        r_width={roomInfo?.wheelchair_info.restroom_width} 
                        r_height={roomInfo?.wheelchair_info.restroom_height} 
                        r_bathhub={roomInfo?.wheelchair_info.restroom_bathtub_height} 
                        r_toilet = {roomInfo?.wheelchair_info.restroom_toilet}
                        props_id="restroom"></Card>
                    <Card title="침실" 
                        roomInfo={roomInfo || null} 
                        b_height={roomInfo?.wheelchair_info.bed_height}
                        b_space={roomInfo?.wheelchair_info.bed_space}
                        props_id="bedroom"></Card>

                </SBodyContent>
                <ScrollToTopButtonWrapper>
                    {isVisible && (
                        <ScrollToTopButtonIcon onClick={scrollToTop}>
                        <i className="fas fa-arrow-up"></i>
                        </ScrollToTopButtonIcon>
                    )}
                </ScrollToTopButtonWrapper>
                <div className="knock-bottom"></div>
            </div>
 
            <Modal
                isOpen={modalOpen}
                height="100vh"
                backgroundColorOverlay="rgb(0 0 0 / 68%);"
                justifyContent="center">
                <div className="top-section">
                    <STopBox>
                        <SImgWrapper alt="knock_space_banner" src={CloseIcon} onClick={() => setModalOpen(false)}/>
                    </STopBox>
                </div>
                <iframe 
                    src={roomInfo?.room_3d || null}
                    style={{ width: '100%', height: 'calc(100% - 150px)', backgroundColor: 'white'}}
                >  
                </iframe>
                <SNav>
                    <SBottom onClick={()=>setModalOpen(false)}>
                            <i class="fa-solid fa-angle-left fa-lg"></i>                                       
                    </SBottom>
                </SNav>
            </Modal>
                           
        </div>
    )
}
const SNav = styled.div`
    padding:10px 0px;
    background-color:#fff;
`;
const SBottom = styled.div`
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
`;
const STitleBox = styled.div`
    line-height:32px;
    
`;
const STitle = styled.div`
    font-size:24px;
    font-weight:800;
    display:flex;
    gap:10px;
    align-items: center;
`;
const STitle3DButton = styled.div`
    font-size: 14px;
    border: 2px solid #00B6F1;
    color: #00B6F1;
    padding: 0px 10px;
    border-radius: 30px; 
    cursor:pointer;
    display:flex;
    gap:5px;
`
const STitleCaption = styled.div`
    font-size:16px;
    color:#aaaaaa;
    font-weight:600;
    display:flex;
    gap: 0px 5px;
    margin: 10px 0px;
    line-height: 24px;
`;
const STitleInfoCaption = styled.div`
    color:#333;
    // font-weight:400;
`;

const SRoomItemWrapper = styled.div`
    display:flex;
    justify-content: space-around;
    margin:10px 0px;
    border-top:2px solid #eee;
    border-bottom : 2px solid #eee;
    padding:20px 0px;
`;
const SRoomItem = styled.div`
    font-size:13px;
    font-weight:600;
    color:#747474;
    text-align: center;
`;
const SRoomInfoIcon = styled.img`
    width:26px;
`;
const SBodyTitle = styled.div`
    font-size: 20px;
    font-weight:600;
    color:#333;
    display:flex;
`;
const SBodyContent = styled.div`
    // display:flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // width:100%;
    margin: 20px 0px;
`;
const STopBox = styled.div`
    display:flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    justify-content: flex-end;
    padding: 0px 10px;
`;
const SImgWrapper = styled.img`
    width:20px;
    cursor:pointer;
    margin: 10px 5px;
`;
const ScrollToTopButtonWrapper = styled.div`
    position: fixed;
    bottom: 70px; /* 원하는 거리 조정 */
    right: 50%; /* 화면 가로 중앙에 위치 */
    transform: translateX(50%); /* 화면 가로 중앙에 위치 */
    z-index: 999; /* 다른 요소 위에 레이어를 정의합니다. */
`;

const ScrollToTopButtonIcon = styled.div`
    background-color: #7474748a;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 999; /* 다른 요소 위에 레이어를 정의합니다. */
  
  &:hover {
    background-color: #747474;
  }
`;
export default RoomDetail;