// Container/styles.js
import styled from 'styled-components';
export const SInputWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;
    gap:20px 0px;
    margin:20px 0px;
`;
export const SInput = styled.input`
    width:calc(100% - 20px);
    height:20px;
    border-radius:5px;
    border:1px solid #ccc;
    margin-bottom: 10px;
    padding: 8px 10px;
    font-size: 14px;
`
export const STextArea = styled.textarea`
    width:calc(100% - 20px);
    height:20px;
    border-radius:5px;
    border:1px solid #ccc;
    margin-bottom: 10px;
    padding: 8px 10px;
    font-size: 14px;
    font-weight:600;
    height:200px;
`