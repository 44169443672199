import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import CarouselWrapper from "../components/Carousel/CarouselWrapper";
import dummy from "../assets/json/dummy.json";
import Tabs from "../components/Tab/Tabs";
import TabContent1 from "../components/Tab/TabContent1";
import TabRoom3D from "../components/Tab/TabRoom3D";
import TabContent2 from "../components/Tab/TabContent2";
import TabContent3 from "../components/Tab/TabContent3";
import Top from "../components/Header/Top";
import AccessibilityBox from "../components/Box/AccessibilityBox";
import { fetchAccommodation } from "../utils/api";


const HotelDetail = () =>{
    const params = useParams();
    const [accommodationInfo, setAccommodationInfo] = useState([]);
    const [accommodation, setAccommodation] = useState([]);
    const hotel = dummy.hotels.filter(data => data.id == params.accommodationId);
    const tabData = [
        {
          label: '객실',
          content: <TabContent1 hotel={hotel}/>,
        },
        {
          label: '정보',
          content: <TabContent2 hotel={hotel}/>,
        },
        {
          label: '리뷰',
          content: <TabContent3 hotel={hotel}/>,
        }
        // {
        //     label: '3D',
        //     content: <TabRoom3D hotel={hotel}/>,
        // },
    ];
    const getAccommodation = async () => {
        try {
            const data = await fetchAccommodation(params.accommodationId);
            setAccommodation(data.result);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(()=>{
        setAccommodationInfo(dummy.hotels.filter(data => data.id == params.accommodationId));
        getAccommodation();
    },[])
    return(
        <div id="child">
            <div id="hotel-detail-page">
                <STopSection className="top-section">
                    <Top></Top>
                </STopSection>
                <div>
                    <CarouselWrapper param='hotels' slides={hotel[0]?.hotel_photos} width='100%' radius="0px" body={false} buttons={true}/>
                </div>
                <STitleSection>
                    <STitleBox>
                        <STitle>
                            {hotel[0]?.hotel_name}
                        </STitle>
                        <STitleCaption>
                            <STitleInfoCaption>{hotel[0]?.hotel_address}</STitleInfoCaption>
                             {/* {accommodation?.hotel_item.hotel_breakfast && <STitleCaptionTag>조식운영</STitleCaptionTag>} */}
                        </STitleCaption>
                    </STitleBox>
                </STitleSection>
                <AccessibilityBox hotel={hotel[0]}/>
                <Tabs tabs={tabData}/>
            </div>
        </div>
    )
}
const STopSection = styled.div`
    background-color:white;
`
const STitleSection = styled.div`
    padding: 10px 20px;
    background-color: white;
`;
const STitleBox = styled.div`
    line-height:32px;
    
`;
const STitle = styled.div`
    font-size:20px;
    font-weight:800;
`;
const STitleCaption = styled.div`
    font-size:16px;
    color:#aaaaaa;
    font-weight:600;
    display:flex;
    gap: 0px 5px;
    justify-content: space-between;
`;
const STitleCaptionTag = styled.div`
    font-size: 14px;
    padding: 0px 10px;
    background-color: #565656;
    border-radius: 30px;
    color: #fff;
`;
const STitleInfoCaption = styled.div`
    color:#333;
    // font-weight:400;
`;


export default HotelDetail;