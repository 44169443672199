import React from "react";

const CouponPolicy = ({title}) =>{
    return(
        <>
            <h3>
                {title} 쿠폰 유의사항
            </h3>
            <div className="text-align-left">
                <ul>
                    <li>쿠폰을 중복으로 사용할 수 없습니다.</li>
                    <li>쿠폰 만료일은 체크 아웃 날짜를 기준으로 적용됩니다.</li>
                    <li>쿠폰 만료일이 지난 후에는 예약 취소 후 다시 사용할 수 없습니다.</li>
                </ul>
             </div>
        </>
    )
}

export default CouponPolicy;