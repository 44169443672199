import React from "react";
import { styled } from "styled-components";

// Modal 컴포넌트를 사용하여 모달을 표시
// 사용 방법
//   <Modal 
//   isOpen={isModal}
//   height="100vh"
//   backgroundColor="rgb(0 0 0 / 68%);"
//   justifyContent="center">

//   {/* 모달 내용을 감싸는 박스 */}
//   <div className="modal-box">

//     {/* 모달 닫기 버튼 */}
//     <div className="modal-box-close">
//       <i onClick={() => setIsModal(false)} className="fa-solid fa-xmark"></i>
//     </div>

//     {/* 자식 컴포넌트를 모달 박스 내에 렌더링 */}
//     <CouponPolicy title="XX리조트"></CouponPolicy>
//   </div>
// </Modal>

function Modal(props) {
    if (!props.isOpen) {
      return null;
    }
    return (
      <SModalOverlay onClick={props.onClose}  backgroundColorOverlay={props.backgroundColorOverlay}>
        <SModal onClick={e => e.stopPropagation()} height={props.height} backgroundColor={props.backgroundColor} justifyContent={props.justifyContent}>
          {props.children}
        </SModal>
      </SModalOverlay>
    );
  }
  

  const SModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => (props.backgroundColorOverlay && props.backgroundColorOverlay)};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  `;

  const SModal = styled.div`
    background-color:${(props) => (props.backgroundColor && props.backgroundColor)};
    padding: 20px 0px;
    border-radius: 4px;
    width:100%;
    max-width: 500px;
    @media (min-width: 420px) {
      width: 100%; 
    }
    height:${(props) => (props.height && props.height)};
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.justifyContent && props.justifyContent)};
    overflow:auto;
    ::-webkit-scrollbar {
        display: none;
    }
  `;
  export default Modal;