import { Link } from "react-router-dom";
import styled from "styled-components";

export const SLoginSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap:10px 0px;
`;
export const SLogoImg = styled.img`
    width:100px;
    margin:20px 0px;
`;
export const SInputContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap:20px 0px;
    width:300px;
    align-items: center;
`;
export const SInput = styled.input`
    width:300px;
    border-radius:5px;
    border: 1px solid rgba(18, 19, 49, .25);
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .1);
    color: #121331;
    padding: 10px 12px;
    position: relative;
    font-size: 14px;
    line-height: 1.5;
`
export const SInputCaption = styled.div`
    color: #333;    
    line-height: 20px;
    font-size:12px;
    opacity: .8;
    margin:5px 0px;
    text-align:center;
    color:red;
`
export const SButton = styled.div`
    width: 100%;
    background-color: #00b6f1;
    border-color: #b8b8b8;
    border-radius: 10px;
    padding: 12px 0px;
    color:white;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-align:center;
    cursor:pointer;
`
export const SRegisterLink = styled(Link)`
    color: #00b6f1;
    border-bottom: 1px solid rgba(41, 98, 255, .01);
    font-weight: 500;
    text-decoration: none;
    text-align:center;
`