import styled from "styled-components";

export const SProfileImgSection = styled.div`
    padding:20px;
    border-top:10px solid #eee;
    text-align:center;

`;
export const SProfileBody = styled.div`
    padding:20px;
    border-top:10px solid #eee;
`;
export const SProfileBodyTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const SProfileFooter = styled.div`
    padding:20px;
    border-top:10px solid #eee;
`;
export const SH2 = styled.h2`
    padding:20px 0px;
`;
export const SButton = styled.div`
    padding:15px 10px;
    text-align:center;
    border-radius:30px;
    background-color:#eee;
    color:#333;
    font-size:16px;
`;
export const SImage = styled.img`
    width:80px;
    height:80px;
    object-fit: contain;
`;
export const SProfileImgWrapper = styled.div`
    margin:auto;
    border-radius:50%;
    border:2px solid #eee;
    width:100px;
    height:100px;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:5px;
`;