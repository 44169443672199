import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Top from "../../components/Header/Top";
import { setAccessToken } from "../../redux/reducer";
import { refreshToken } from "../../utils/common";
import { getMyInfo } from "../../utils/api";
import styled from "styled-components";
import { SInput } from "../../components/Input/styles/input";
import ProfileImg from "../../assets/main-logo.png";
import { SButton, SH2, SImage, SProfileBody, SProfileBodyTitle, SProfileFooter, SProfileImgSection, SProfileImgWrapper } from "./styles";
const MyProfilePage = () => {
    const [userInfo, setUserInfo] = useState();
    const [userEmail, setUserEmail] = useState('');
    const [userContact, setUserContact] = useState('');
    const accessToken = useSelector(state=> state.auth.accessToken);
    const dispatch = useDispatch();
    useEffect(()=>{
        const fetchMe = async () =>{
            try {
                const data = await getMyInfo(accessToken);
                if(data.ret) {
                    setUserInfo(data.ret);
                }
                else {
                    /* 로그 코드*/
                    console.log("다음과 같은 에러가 있습니다. " + data.error_code);
                    
                    switch(data.error_code) {
                        case 'AUTH_TOKEN_EXPIRED':
                            console.log('토큰 만기로 토큰을 refresh 하겠습니다. ');
                            dispatch(setAccessToken(await refreshToken()));
                            break;
                        case 'AUTH_CREDENTIALS_INVALID':
                            console.log('적절하지 않은 토큰(null)으로 토큰을 refresh 하겠습니다. ');
                            dispatch(setAccessToken(await refreshToken()));
                            break;
                      }       
                }
            } catch (error) {
                console.log("예상치 못한 에러입니다.");
                console.error(error);
            }
        }
        fetchMe();
    },[accessToken])
    return(
        <div id="child">
            <div className="top-section">
                <Top title="내 프로필"></Top>
            </div>
            <SProfileImgSection>
                <SProfileImgWrapper>
                    <SImage src={ProfileImg}></SImage>
                </SProfileImgWrapper>
            </SProfileImgSection>
            <SProfileBody>
                <SProfileBodyTitle>
                    <SH2>회원정보</SH2>
                    <div className="cursor-pointer">수정</div>
                </SProfileBodyTitle>
                <SInput value={userInfo?.user_email} onChange={(e)=>setUserEmail(e.target.value)}></SInput>
                <SInput value={userInfo?.user_phone} onChange={(e)=>setUserContact(e.target.value)}></SInput>

            </SProfileBody>
            <SProfileFooter>
                <SButton className="cursor-pointer">
                    회원 탈퇴
                </SButton>
            </SProfileFooter>
        </div>
    )
}

export default MyProfilePage;