// Container/styles.js
import styled from 'styled-components';

export const SCardBody = styled.div`
    display:flex;
    align-items: center;
    padding: 10px;
    gap:10px;
`
export const SCardLeft = styled.div`
    width:100px;
    height:100px;
`;
export const SCardFooter = styled.div`
    background-color:#FFFFFF;
    padding: 10px 0px;
    color:#00B6F1;
    border-top:1px solid #e1e1e1;
`
export const SCardFooterDeactivated = styled.div`
    background-color:#FFFFFF;
    padding: 10px 0px;
    width:100%;
    color:#c4c4c4;
    border-top:1px solid #e1e1e1;
`
export const SCardFooterCancled = styled.div`
    background-color:#FFFFFF;
    padding: 10px 0px;
    width:100%;
    color:#ff5252;
    border-top:1px solid #e1e1e1;
`
export const SCardCaption = styled.div`
    color:#747474;
    font-size:14px;
    margin:10px 0px;
    font-weight:600;
`
export const SImgWrapper = styled.img`
    width:100%;
    height:100%;
    border-radius:10px;
`