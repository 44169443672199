import Data from "../assets/json/dummy.json";
const getItemByRoom = (d, k) =>{
    let result = '';
    const foundRoom = d.rooms.find(room => room.title === k);
    result = foundRoom;
    return result;
}
const getItemByHotel = (k) =>{
    let result = '';
    const foundHotel = Data.hotels.find(hotel => hotel.hotel_name === k);
    if(foundHotel) result = foundHotel;
    return result;
}
const getTmpImg = (order) => {
    const tmpImg = Data.hotels?.filter(hotel=>hotel.id === order.accommodation_id)[0]?.hotel_photos[0];
    return tmpImg;
}
export {getItemByHotel, getItemByRoom, getTmpImg};