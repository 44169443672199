import React, { useEffect, useState } from "react";
import dummy from "../../assets/json/dummy.json";
import { styled } from "styled-components";
import PrevIcon from "../../assets/images/prev_icon.svg";
import { useNavigate } from "react-router-dom";
import Bottom from "../Bottom/Bottom";
import HotelCard from "../Card/HotelCard";

const Search = () =>{
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(dummy.hotels);
    const [wheelchairRoom, setWheelchairRoom] = useState(false);
    const [parking, setParking] = useState(false);
    const [breakfast, setBreakfast] = useState(false);
    useEffect(()=>{
        // document.querySelector('.nav-container').style.display='block';
    },[])
    const navigate = useNavigate();
    const movePrev = () =>{
       navigate(-1);
    }
    
    function handleSearchChange(event) {
        setSearchTerm(event.target.value);
      
        if (event.target.value === "") {
          setSearchResults(dummy.hotels);
          return;
        }
        const results = dummy.hotels.filter(item => 
          item.hotel_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item.hotel_address.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setSearchResults(results);
    }
    const filteredHotels = searchResults.filter(hotel => {
        return (!wheelchairRoom || hotel.haveWheelChairRoom) &&
               (!parking || hotel.hotel_wheelchair_item.hotel_wheelchair_parking) &&
               (!breakfast || hotel.hotel_item.hotel_breakfast);
        // 추가로 필터링 조건이 필요하면 위와 같은 방식으로 확장
    });
   
    return(
        <>
            <SSearchTop className="top-section">
                <STopBoxLeft>
                    <SImgWrapper alt="knock_space_banner" src={PrevIcon} onClick={ movePrev } />
                </STopBoxLeft>
                <SSearchBox 
                    type="text"
                    placeholder="장애인 객실은? 노크노크!"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </SSearchTop>
           
            <div>
                <input id="check_btn1" type="checkbox" checked={wheelchairRoom} onChange={(e) => setWheelchairRoom(e.target.checked)} />   
                <label className="badge" for="check_btn1"> 장애인 객실 </label>
              
                <input id="check_btn2" type="checkbox" checked={parking} onChange={(e) => setParking(e.target.checked)} />
                <label className="badge" for="check_btn2"> 장애인 주차장 </label>
                
                <input id="check_btn3" type="checkbox" checked={breakfast} onChange={(e) => setBreakfast(e.target.checked)} />
                <label className="badge" for="check_btn3"> 조식 운영 </label>
            {/* 추가로 필요한 서비스 체크박스를 여기에 추가 */}
      </div>
     
            {filteredHotels.map(hotel => (
            <HotelCard hotel={hotel}></HotelCard>
        ))}
        <Bottom></Bottom>
        </>
    )
}
const SSearchTop = styled.div`
    display:flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    justify-content: flex-start;
    padding: 10px 0px;
`;
const STopBoxLeft = styled.div`
    display:flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
`;
const SImgWrapper = styled.img`
    width:40px;
    cursor:pointer;
`;
const SSearchBox = styled.input`
    width:75%;
    max-width:380px;
    border-radius:10px;
    padding:10px;
    margin: 0px 10px 0px 0px;
    border:1px solid #ccc;
`;


export default Search;