import React from "react";
import bannerImg from "../../assets/images/banner2.png";
const Banner = () =>{

    return(
        <>
            <img id="img-frame" alt="knock_space_banner" src={bannerImg}>
            
            </img>
        </>
    )
}

export default Banner;