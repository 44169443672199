import React, { useState } from "react";
import { styled } from "styled-components";
import CheckedIcon from "../../assets/images/checked-mark.png"
import UncheckedIcon from "../../assets/images/unchecked-mark.png";
import ElevatorIcon from "../../assets/images/elevator_icon.png";
import WheelchairIcon from "../../assets/images/wheelchair_parking_icon.png";
import SlopeIcon from "../../assets/images/slope_icon.png";
import Modal from "../Modal/Modal";
import CloseIcon from "../../assets/images/close_icon_white.png";

const AccessibilityBox = (props) =>{

    const [parkingModalOpen, setParkingModalOpen] = useState(false);
    const [entranceModalOpen, setEntranceModalOpen] = useState(false);
    const [elevatorModalOpen, setElevatorModalOpen] = useState(false);

    const hotel = props.hotel;
    return(
        <>
            <SIconBox>
                    <SIconWrapper  
                        onClick={() => {
                            // setParkingModalOpen(true);
                    }}>
                        <SIcon src={WheelchairIcon}></SIcon>
                            <SIconCaption>
                                {
                                    hotel?.hotel_wheelchair_item?.hotel_wheelchair_parking ?
                                    <SIcon2 src={CheckedIcon}/> : <SIcon2 src={UncheckedIcon}/>
                                }       
                                장애인 주차장
                            </SIconCaption>
                    </SIconWrapper>
                    
                        {
                                hotel?.hotel_wheelchair_item?.hotel_wheelchair_parking ?
                                <Modal
                                    isOpen={parkingModalOpen}
                                    height="100vh"
                                    backgroundColor="rgb(0 0 0 / 68%);"
                                    justifyContent="center">
                                        <div className="top-section">
                                            <STopBox>
                                                <SImgWrapper alt="knock_space_banner" src={CloseIcon} onClick={() => setParkingModalOpen(false)}/>
                                            </STopBox>
                                        </div>
                                        <img src={hotel?.hotel_wheelchair_item_photo?.wheelchair_parking}  style={{ width: '500px', height: '300px' }}/> 
                                    </Modal>
                                : <></>
                        }   
                    
                
            
                    <SIconWrapper
                        onClick={() => {
                            // setEntranceModalOpen(true);
                    }}>
                        <SIcon src={SlopeIcon}></SIcon>
                            <SIconCaption>
                                {
                                    hotel?.hotel_wheelchair_item?.hotel_wheelchair_entrance ?
                                    <SIcon2 src={CheckedIcon}/> : <SIcon2 src={UncheckedIcon}/>
                                }     
                                출입 가능 
                            </SIconCaption>
                    </SIconWrapper>
                    {
                            hotel?.hotel_wheelchair_item?.hotel_wheelchair_entrance ?
                            <Modal
                                isOpen={entranceModalOpen}
                                height="100vh"
                                backgroundColor="rgb(0 0 0 / 68%);"
                                justifyContent="center">
                                    <div className="top-section">
                                        <STopBox>
                                            <SImgWrapper alt="knock_space_banner" src={CloseIcon} onClick={() => setEntranceModalOpen(false)}/>
                                        </STopBox>
                                    </div>
                                    <img src={hotel?.hotel_wheelchair_item_photo?.wheelchair_entrance}  style={{ width: '500px', height: '300px' }}/>
                            </Modal>
                            : <></>
                    }
                    <SIconWrapper
                    onClick={() => {
                        // setElevatorModalOpen(true);
                    }}>
                        <SIcon src={ElevatorIcon}></SIcon>
                            <SIconCaption>
                                {
                                    hotel?.hotel_wheelchair_item?.hotel_wheelchair_entrance ?
                                    <SIcon2 src={CheckedIcon}/> : <SIcon2 src={UncheckedIcon}/>
                                }        
                                엘리베이터
                            </SIconCaption>
                    </SIconWrapper>
                    {
                        hotel?.hotel_wheelchair_item?.hotel_wheelchair_entrance ?
                            <Modal
                            isOpen={elevatorModalOpen}
                            height="100vh"
                            backgroundColor="rgb(0 0 0 / 68%);"
                            justifyContent="center">
                            <div className="top-section">
                                <STopBox>
                                    <SImgWrapper alt="knock_space_banner" src={CloseIcon} onClick={() => setElevatorModalOpen(false)}/>
                                </STopBox>
                            </div>
                            <img src={hotel?.hotel_wheelchair_item_photo?.wheelchair_elevator}  style={{ width: '500px', height: '300px' }}/>
                        </Modal>
                            :<></>
                    }
                    
            </SIconBox>
        </>
        
    )
}
const STopBox = styled.div`
    display:flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    justify-content: flex-end;
    padding: 0px 10px;
`;
const SImgWrapper = styled.img`
    width:20px;
    cursor:pointer;
    margin: 10px 5px;
`;
const SIconBox = styled.div`
    display:flex;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    padding:20px;
    gap: 30px 10px;
    flex-wrap: wrap;
    font-size:14px;
    background-color: white;
    margin: 5px 0px 0px 0px;
`;
const SIconWrapper = styled.div`
    display:flex;
    gap:10px 0px;
    text-align: center;
    font-weight:600;
    flex-direction: column;
    align-items: center;
    padding:10px;
    border-radius:10px;
`;
const SIconCaption = styled.div`
    display: flex;
    align-items: center;
`;
const SIcon = styled.img`
    width:30px;
`;
const SIcon2 = styled.img`
    width:12px;
    margin-right:5px;
`;
export default AccessibilityBox;