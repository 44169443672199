import React from "react";
import ReservationCard from "../Card/ReservationCard";
import '../../styles/tab.css';
import { useNavigate } from "react-router-dom";
import { getTmpImg } from "../../utils/getItemBy";
import { SCardBody, SCardCaption, SCardFooter, SCardFooterDeactivated, SCardLeft, SImgWrapper } from "./styles/tab";

const TabBeforeUse = (props)=>{
    const isReserving =props.orders || [];
    const navigate = useNavigate();
    const move = (order) => {
        navigate('/hotels/'+ order.order_id +'/' + order.order_id + '/reserve',{
            state:{
                order : order,
                hotel: order.accommodation_name,
                room:order.room_name
            }
        });
    }
    // const isReservingFilter = props.orders.result.filter(order=> order.order_status="order_created");
    // console.log(isReservingFilter)
    return(
        <div className="tab-wrapper">
             {isReserving.map((order) => (
                // ReservationCard 컴포넌트에 key 추가
                <ReservationCard key={order.order_id} title={order.accommodation_name} order={order}> 
                    <SCardBody>
                        <SCardLeft>
                            <SImgWrapper src={getTmpImg(order)}></SImgWrapper>
                        </SCardLeft>
                        <div>
                                {order.accommodation_name}
                            <SCardCaption>
                                {order.room_name}
                            </SCardCaption>
                            <SCardCaption>
                                {order.order_check_in} ~ {order.order_check_out}
                            </SCardCaption>
                            
                        </div>
                      
                    </SCardBody>
                        {order.order_status === "approved" ? 
                        <SCardFooter className="button-cta" onClick={() => move(order)}>
                            {/* <Link 
                                className="link"
                                to="/hotels/1/1/reserve"
                            > */}
                                     주문번호 {order.order_id} 예약 가능
                            {/* </Link>  */}
                        </SCardFooter>
                        : 
                        <SCardFooterDeactivated className="button-deactivated"> {order.order_id} 예약 대기</SCardFooterDeactivated> }
                </ReservationCard>
            ))}
        </div>
    )
}

export default TabBeforeUse;