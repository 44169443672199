import React, { useRef, useState } from 'react';
import { format, isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

export default function DatePickerDialog({onStartDateChange, onEndDateChange }) {
  const [selectedRange, setSelectedRange] = useState({ from: null, to: null });
  const [inputValues, setInputValues] = useState({ from: '', to: '' });
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [currentInput, setCurrentInput] = useState('from'); // 현재 활성화된 입력 필드

  const popperRef = useRef(null);
  const fromInputRef = useRef(null);
  const toInputRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    if (currentInput === 'from') {
      fromInputRef.current && fromInputRef.current.focus();
    } else {
      toInputRef.current && toInputRef.current.focus();
    }
  };

  const handleInputChange = (type) => (e) => {
    setInputValues(prev => ({ ...prev, [type]: e.currentTarget.value }));
    const date = parse(e.currentTarget.value, 'y-MM-dd', new Date());
    if (isValid(date)) {
      setSelectedRange(prev => ({ ...prev, [type]: date }));
    } else {
      setSelectedRange(prev => ({ ...prev, [type]: null }));
    }
  };

  const handleInputClick = (type) => () => {
    setCurrentInput(type);
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date) => {
    if (currentInput === 'from') {
      if (isValid(date)) {
        setInputValues({ from: format(date, 'y-MM-dd'), to: inputValues.to });
        setSelectedRange({ from: date, to: selectedRange.to });
        onStartDateChange({ ...date, startDate: date });
      }
    } else {
      if (isValid(date)) {
        setInputValues({ from: inputValues.from, to: format(date, 'y-MM-dd') });
        setSelectedRange({ from: selectedRange.from, to: date });
        onEndDateChange({ ...date, endDate: date });
        closePopper(); // 종료일을 선택한 후 팝업을 닫습니다.
      }
    }
  };

  const today = new Date();
  const sixMonthsFromNow = new Date(today);
  sixMonthsFromNow.setMonth(today.getMonth() + 6);

  const disabledDays = {
      before: today,
      after: sixMonthsFromNow
  };
  return (
    <SCalenderSection>
      <SCalenderInputContainer ref={popperRef}>
        <SCalenderInput
          ref={fromInputRef}
          size="12"
          type="text"
          placeholder="시작일"
          value={inputValues.from}
          onChange={handleInputChange('from')}
          onClick={handleInputClick('from')}
        />
        <SCalenderInput
          ref={toInputRef}
          size="12"
          type="text"
          placeholder="종료일"
          value={inputValues.to}
          onChange={handleInputChange('to')}
          onClick={handleInputClick('to')}
        />
      </SCalenderInputContainer>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: fromInputRef.current || undefined
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            aria-label="DayPicker calendar"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={selectedRange.from || selectedRange.to}
              selected={currentInput === 'from' ? selectedRange.from : selectedRange.to}
              onSelect={handleDaySelect}
              disabled={disabledDays}
            />
          </div>
        </FocusTrap>
      )}
    </SCalenderSection>
  );
}
const SCalenderSection = styled.section`
    width:100%;
`;

const SCalenderInputContainer = styled.div`
    display:flex;
    gap:0px 10px;
`;
const SCalenderInput = styled.input`
    width: calc(100% - 20px);
    height: 20px;
    padding: 8px 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    transition: border-color 0.3s ease;
    cursor:pointer;
`;