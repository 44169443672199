import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../assets/images/close_icon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../styles/calender.css"
import { getAuth } from "firebase/auth";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk"
import { createPayment } from "../../utils/api";
import { SInput, SInputWrapper, STextArea } from "../../components/Input/styles/input";
import ReserveAgreement from "../../components/Policy/ReserveAgreement";
import Bottom from "../../components/Bottom/Bottom";
import { SAlertMsg, SImgWrapper, SModalBody, SModalHeader, SModalTitle, SSectionTitle, STopBox, SUserInfo } from "./styles";
import ReservationInfoSection from "./ReservationInfoSection";
import MyReservationCoupon from "./ReservationCoupon";
import ReservationPaymentSection from "./ReservationPaymentSection";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken } from "../../redux/reducer";
import { refreshToken } from "../../utils/common";
import { formatCntDay } from "../../utils/formatter";
const ReserveCard = () =>{
    const navigate = useNavigate();
    const [reserveName, setReserveName] = useState('');
    const [reserveEmail, setReserveEmail] = useState(''); 
    const [reservePhone, setReservePhone] = useState('');
    const [alertMsg, setAlertMsg] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [allAgreed, setAllAgreed] = useState(false);
    const [totalCost, setTotalCost] = useState();
    const [selectedCoupon, setSelectedCoupon] = useState('');
    const [couponCost, setCouponCost] = useState('');

    const { hotelName, roomType } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const accessToken = useSelector(state=> state.auth.accessToken);

    const {state} = location;
    const auth = getAuth();
    
    const user = auth.currentUser; 
    const clientKey = process.env.REACT_APP_PG_TEST_KEY;
    const customerKey = user.email    
    const movePrev = () =>{
        console.log("move")
        navigate(-1);
    }    
    const handleAllAgree = (isAllAgreed) => {
        setAllAgreed(isAllAgreed);
    };
    const paymentWidgetRef = useRef(null);
    const paymentMethodsWidgetRef = useRef(null);

    const handlePaymentRequest = async () => {
        if (allAgreed) {
            const paymentWidget = paymentWidgetRef.current;
            const paymentMethodsWidget = paymentMethodsWidgetRef.current;
            try {
             
                const data = await createPayment(accessToken, {
                    "order_id" : state.order.order_id,
                    "user_id" : state.order.user_id,
                    "guest_name": reserveName,
                    "guest_number" : reservePhone
                })
                paymentMethodsWidget.updateAmount(data.result.payment_cost);
                await paymentWidget?.requestPayment({
                    orderId: data.result.order_id,
                    orderName: data.result.order_name,
                    customerName: data.result.guest_name,
                    customerEmail: data.result.guest_number,
                    successUrl: data.result.success_url,
                    failUrl : data.result.fail_url
                });
            } catch (error) {
                console.error(error);
                switch(error) {
                    case 'AUTH_TOKEN_EXPIRED':
                        console.log('토큰 만기로 토큰을 refresh 하겠습니다. ');
                        dispatch(setAccessToken(await refreshToken()));
                        break;
                    case 'AUTH_CREDENTIALS_INVALID':
                        console.log('적절하지 않은 토큰(null)으로 토큰을 refresh 하겠습니다. ');
                        dispatch(setAccessToken(await refreshToken()));
                        break;
                  }      
            }
        } else {
            setAlertMsg("약관에 동의를 하셔야 예약하실 수 있습니다.");
        }
      };
    
    useEffect(()=>{
        (async () => {
            const paymentWidget = await loadPaymentWidget(clientKey, customerKey);
            const paymentMethodsWidget = paymentWidget.renderPaymentMethods("#payment-method", parseInt(state.order.price_cost));            
            paymentWidgetRef.current = paymentWidget;
            paymentMethodsWidgetRef.current = paymentMethodsWidget;

        })();
        setReserveEmail(user?.email);
        setReserveName(user?.name); 
        const total = (state.order.total_cost) * formatCntDay(state.order.order_check_in, state.order.order_check_out, null)
        setTotalCost(total);
    }, [user?.email, user?.name])
    useEffect(()=>{

    },[selectedCoupon, totalCost])
    return(
        <div id="child">
            <div>
                    <STopBox>
                            <SImgWrapper alt="knock_space_banner" src={CloseIcon} onClick={movePrev}/>
                    </STopBox>
                    <SModalTitle>예약하기</SModalTitle>
                    <SModalBody>
                        <SSectionTitle>
                            숙소
                        </SSectionTitle>
                        <ReservationInfoSection order={state.order}/>
                        <SSectionTitle>
                            예약자 정보
                        </SSectionTitle>
                        <SUserInfo>
                            {user?.email}
                        </SUserInfo>
                        <SSectionTitle>
                            이용자 정보
                        </SSectionTitle>
                        <SInputWrapper>
                            <SInput type='text' placeholder={'이용자 이름'} value={reserveName} onChange={(e)=> setReserveName(e.target.value)}></SInput>
                            <SInput type='text' placeholder={'이용자 연락처'} value={reservePhone} onChange={(e)=> setReservePhone(e.target.value)}></SInput>

                        </SInputWrapper>
                        <SSectionTitle>
                            쿠폰 사용
                        </SSectionTitle>
                        <MyReservationCoupon  selectedCouponCost={setCouponCost} selectedValue={selectedCoupon} setSelectedValue={setSelectedCoupon}></MyReservationCoupon>
                        <SSectionTitle>
                            결제 금액 
                        </SSectionTitle>
                        <ReservationPaymentSection totalCost={totalCost} couponCost={couponCost} setCost={setTotalCost}></ReservationPaymentSection>
                        <SSectionTitle>
                            결제 방법
                        </SSectionTitle>
                        <div id="payment-method"></div>
                        {/* <div id="agreement"></div> */}
                        <ReserveAgreement 
                            hotel_name={hotelName} 
                            start_date={startDate} 
                            end_date={endDate} 
                            all_agree={handleAllAgree}
                        ></ReserveAgreement>
                        <SAlertMsg>{alertMsg}</SAlertMsg>
                        <div 
                        className="knock-cta-button2"
                        id="payment-button"
                        onClick={()=>{
                                //  fetchPayment();
                                 handlePaymentRequest();
                            }
                        }
                        >
                            예약하기
                        </div>
                    </SModalBody>
                    <Bottom></Bottom>
            </div>
            
        </div>
    )
}

export default ReserveCard;