import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CarouselWrapper from "../../components/Carousel/CarouselWrapper";
import dummy from "../../assets/json/dummy.json";
import Banner from "../../components/Banner/Banner";
import Logo from "../../components/Logo/Logo";
import Footer from "../../components/Bottom/Footer";
import Bottom from "../../components/Bottom/Bottom";
import { fetchAccommodation, fetchAccommodations } from "../../utils/api";
import { IconButton, Snackbar, Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
const MainPage = () =>{
    const [accommodations, setAccommodations] = useState([]);
    const [isToolTip , setIsToolTip] = useState(false);
    const [open, setOpen] = useState(false);

    const location = useLocation();
    const myDataFromState = location.state?.snackBar;

    const slides_seciton1 = dummy.hotels;
    const slides_section2 = dummy.hotels
        .filter(hotel => hotel.rooms[0].hasOwnProperty('room_3d'))
        .sort((a, b) => a.hotel_name.localeCompare(b.hotel_name));
    
    const getAccommodation = async () => {
        try {
            const data = await fetchAccommodations();
            setAccommodations(data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(()=>{
        setOpen(myDataFromState);
        getAccommodation();
    },[])
    return(
        <div id="child">
             <Snackbar 
                open={open} 
                onClose={() => setOpen(false)} 
                message="로그인에 성공하였습니다."
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            />
            <div>
                <SLogoSection>
                <Logo></Logo>
                </SLogoSection>
                <Banner></Banner>
                <SContainer>
                    <div id="container-title">노크노크가 방문한 객실!🏨</div>
                    <div id="container-body">
                        <CarouselWrapper param='hotels' slides={slides_seciton1}  width='150px' height='150px' radius="20px" body={true}></CarouselWrapper>
                    </div>
                    <div id="container-title"> 3D뷰어로 확인이 가능한 객실! 
                        <Tooltip title="노크노크가 직접 촬영했어요! 객실 상세페이지의 3D 뷰어를 통해 객실의 360도 모습을 확인하세요." open={isToolTip} onClick={()=>setIsToolTip(!isToolTip)}>
                            <IconButton>
                                <i className="fa-solid fa-circle-question fa-xs" style={{color: "#00b6f1"}}></i>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div id="container-body">
                        <CarouselWrapper param='hotels' slides={slides_section2} width='150px' height='150px' radius="20px" body={true}></CarouselWrapper>
                    </div>
                </SContainer>
            </div>
            <div>
                <Footer></Footer>
                <Bottom></Bottom> 
            </div>
        </div>
    )
}
const SLogoSection = styled.div`
    padding: 10px 0px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content:  space-between;
`;

const SContainer = styled.div`
    padding: 10px 20px 0px 20px;
`;


export default MainPage;