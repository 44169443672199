// api.js

import { changeToKR } from "./formatter";
const apiUrl = 'https://dev-backend.knockspace.co.kr/'; // API 엔드포인트 URL
export const healthCheck = async () => {
  try {
    const response = await fetch(apiUrl + 'healthcheck');

    if (!response.ok) {
      throw new Error('서버 헬스 체크에 실패했습니다. 상태: ' + response.status);
    }else console.log('서버 헬스체크 성공')

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('서버 헬스 체크 중 오류 발생:', error);
    throw error;
  }
};
export async function signUpEmail(id, pw){
  try {
    const response = await fetch(apiUrl+ 'auth/v1/signup-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        "email":id, 
        "password" :pw 
      })
  });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
export async function getAccessToken(token){
  try {
    const response = await fetch(apiUrl  + 'auth/v1/id-token', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: token
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(`${data}`);
  }
    return data;
  } catch (error) {
    console.log(error);
  }
}
// Refresh Token을 사용하여 Access Token 재발행
export async function refreshAccessToken(req){
    try {
      const response = await fetch(apiUrl + 'auth/v1/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
          "access_token" : req.accessToken, 
          "refresh_token" : req.refreshToken,
          "firebase_token" : req.idToken, 
          "token_type" : 'bearer'
      })
    }); 
    if(response.ok) console.log("정상적으로 토큰을 재발급 받았습니다.");
    else {
      console.log('정상적으로 토큰을 재발급하지 못했습니다.') 
      throw await response.json();
    }   
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

// 숙소 데이터 요청 
export async function fetchAccommodations() {
  try {
    const requestOptions = {
      method: 'GET',
    };
    const response = await fetch(apiUrl + `item/v1/accommodations`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 가져오는 중 오류 발생:', error);
    throw error;
  }
}
export async function fetchAccommodation(key) {
  try {
    const requestOptions = {
      method: 'GET',
    };
    const response = await fetch(apiUrl + `item/v1/accommodation/{accommodation_id}?id=${key}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 가져오는 중 오류 발생:', error);
    throw error;
  }
}

// 주문 데이터 요청 

export async function getOrders(token) {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };

    // URL에 k 쿼리스트링 추가
    const url = `order/v1/orders`;
    
    const response = await fetch(apiUrl + url, requestOptions);
    const data = await response.json();
    if(!response.ok){
      console.log(data);
      throw data.error_code;
    }
    return data;
  } catch (error) {
    console.error('데이터를 가져오는 중 오류 발생:', error);
    throw error;
  }
}

export async function createOrder(token, req){
  try {
    // POST 요청을 보내기 위한 설정
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(req), 
    };

    const response = await fetch(apiUrl + 'order/v1/orders', requestOptions);
    const data = await response.json();
    if(!response.ok){
      throw data.error_code;
    }
    return data;
  } catch (error) {
    console.error('데이터를 가져오는 중 오류 발생:', error);
    throw error;
  }
}

// Request Body
// {
//   "order_id": "string",
//   "user_id": "string",
//   "coupon_ids": [
//     "string"
//   ],
//   "guest_name": "string",
//   "guest_number": "string"
// }
export async function createPayment(token, req){
  try {
    // POST 요청을 보내기 위한 설정
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(req), 
    };

    const response = await fetch(apiUrl + 'order/v1/payments', requestOptions);
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
      throw data.error_code;
    }

    return data;
  } catch (error) {
    console.error('데이터를 가져오는 중 오류 발생:', error);
    throw error;
  }
}
export async function getPrices(k){
  try {
    // console.log(k);
    const requestOptions = {
      method: 'GET'
    };

    // URL에 k 쿼리스트링 추가
    const url = `item/v1/prices?room_id=${encodeURIComponent(k)}&limit=100`;
    
    const response = await fetch(apiUrl + url, requestOptions);
    // console.log(response);
    const data = await response.json();
    if(!response.ok){
      throw new Error(`${data.error_code}`);
    }
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}
export async function deletePayment(orderId, userId, amount){
  try {
    // POST 요청을 보내기 위한 설정
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(apiUrl + `order/v1/orders/${orderId}?user_id=${userId}&amount=${amount}`, requestOptions);
    if (!response.ok) {
      throw new Error('데이터를 가져오는 중 오류 발생');
    }

    const data = await response.json();
    console.log('DELETE success:', data);
    return data; // 원하는 경우 서버의 응답을 반환할 수 있습니다.
  } catch (error) {
    console.error('DELETE error:', error.message);
    throw error; // 예외를 던져서 오류를 상위 컴포넌트로 전달할 수 있습니다.
  }
}


export async function getMyInfo(token){
  try {
    const requestOptions ={
      method:'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    const response = await fetch(apiUrl + `user/v1/me`, requestOptions);
    /* 로그 */
    if(response.ok) console.log("정상적으로 MyInfo를 받았습니다.");
    else console.log('정상적이지 않은 토큰으로 정보를 수신하지 못했습니다.')
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function setMyCoupon(key, token){
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body:JSON.stringify({coupon_code:key})
    }
    const response = await fetch(apiUrl + `admin/v1/coupons/${key}`, requestOptions);
    if(response.ok) console.log("정상적으로 쿠폰이 등록되었습니다.")
    else console.log('쿠폰 등록에 실패하였습니다.')
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getMyCoupon(token){
  try {
    const requestOptions ={
      method:'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    const response = await fetch(apiUrl + `admin/v1/coupons/`, requestOptions);
    /* 로그 */
    if(response.ok) console.log("정상적으로 My Coupon 정보를 받았습니다.");
    else console.log('정상적이지 않은 토큰으로 정보를 수신하지 못했습니다.')
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}