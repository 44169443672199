import React from "react";
import { styled } from "styled-components";

const Bottom = () =>{

    return(
        <SBottom>
            
        </SBottom>
    )
}

const SBottom = styled.div`
    height:50px;
    width: 100vw;
    max-width: 500px;
`

export default Bottom;