import React from "react";
import './card.css';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { changeTimeToKR } from "../../utils/formatter";


const ReservationCard = ({children, order}) => {
    return(
        <SCardContainer>
            <SCardWrapper>
                <SCardTitle>
                    {changeTimeToKR(order.created_at)} 
                </SCardTitle>
                <Link className="link" to='/mypage/myReservation/detail' state={{ order : order}}>
                    상세보기
                </Link>
            </SCardWrapper>
            <SCardContent>
                {children}
            </SCardContent>
        </SCardContainer>
    )
}
const SCardContainer = styled.div`
    width: 85%;
    margin:10px 0px 0px;
    // background-color: aliceblue;
`;
const SCardWrapper = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    // margin:10px 0px;
    border-radius : 10px 10px 0px 0px;
    background-color:#ffffff;
    padding:10px;
    border-bottom:1px solid #e1e1e1;
`;
const SCardTitle = styled.div`
    font-size:14px;
    color:#747474;
`
const SCardContent = styled.div`
    background-color:#ffffff;
    padding:10px 0px 0px 0px;
`
export default ReservationCard;