//공통 캐러셀 래퍼 컴포넌트
import Carousel from "./Carousel";
import "../../styles/embla.css";
import dummy from "../../assets/json/dummy.json";

const CarouselWrapper = (props) => {
  // console.log(props)
  const OPTIONS = { align: 'start', containScroll: 'trimSnaps', width : props.width, height : props.height, radius : props.radius, param : props.param, body: props.body, buttons:props.buttons}
  const SLIDE_COUNT = 4
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys())
  return (
    <section className="sandbox__carousel">
      <Carousel slides={props.slides} options={OPTIONS} />
    </section>
  );
};

export default CarouselWrapper;