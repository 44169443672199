import React from "react";

import styled from "styled-components";

const TabContent3 = (hotel)=>{
    const hotelInfo = hotel.hotel[0];
    return(
        <>
            
            {
                hotelInfo.hotel_review &&
                Object.keys(hotelInfo.hotel_review).map((reviewer) => (
                   <STabInfo key={reviewer}>
                        <STabInfoColumnWrapper>
                            <STabReviewHeader> 
                                <STabReviewScoreWrapper>
                                    <STabReviewScore>{hotelInfo.hotel_review[reviewer][0]}</STabReviewScore>/5
                                </STabReviewScoreWrapper> 
                                <strong>{reviewer}</strong>
                            </STabReviewHeader>
                            <STabReviewContent key={reviewer}>
                              {hotelInfo.hotel_review[reviewer][1]}
                            </STabReviewContent>
                        </STabInfoColumnWrapper>
                    </STabInfo>
                ))
                
            }        
        </>
    )
}
const STabInfo = styled.div`
    background-color:white;
    border-radius:10px;
    padding:20px 20px;
    margin: 20px 0px;
`
const STabReviewHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color:#333;
    margin:0px 0px 10px 0px;
    `;
const STabReviewScoreWrapper = styled.div`
    display:flex;
    align-items: center;
`;
const STabReviewScore = styled.div`
    font-size:20px;
    font-weight:700;
`;
const STabReviewContent = styled.div`
    line-height: 26px;
`;
const STabInfoColumnWrapper = styled.div`
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    justify-content: flex-start;
    color:#7b7b7b;
    gap: 5px 5px;

`;
export default TabContent3;