import React, { useEffect, useState } from "react";
import Top from "../../components/Header/Top";
import styled from "styled-components";
import MyCouponCard from "./MyCouponCard";
import { Link } from "react-router-dom";
import { SBodyContent, SBodyTitle, SCouponSection } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getMyCoupon, refreshAccessToken } from "../../utils/api";
import { setAccessToken } from "../../redux/reducer";
import Cookies from "js-cookie";
import { refreshToken } from "../../utils/common";

const MyCouponPage = () =>{
    const [myCoupons, setMyCoupons] = useState([]);
    const dispatch = useDispatch();
    const accessToken = useSelector(state=>state.auth.accessToken);
    
    useEffect(()=>{
        // setMyCoupons(JSON.parse(localStorage.getItem('user')).coupons);
        const getMyCouponsInfo = async() => {
            try {
                const data = await getMyCoupon(accessToken);
                if(data.ret){
                    setMyCoupons(data.ret);
                }else{
                      /* 로그 코드*/
                    console.log("다음과 같은 에러가 있습니다. " + data.error_code);
                    switch(data.error_code) {
                        case 'AUTH_TOKEN_EXPIRED':
                            console.log('토큰 만기로 토큰을 refresh 하겠습니다. ');
                            refreshToken()
                            .then((newToken) => {
                                dispatch(setAccessToken(newToken));
                            });                          
                            break;
                        case 'AUTH_CREDENTIALS_INVALID':
                            console.log('적절하지 않은 토큰(null)으로 토큰을 refresh 하겠습니다. ');
                            refreshToken()
                            .then((newToken) => {
                                dispatch(setAccessToken(newToken));
                            });                          
                            break;
                     }
                }
            } catch (error) {
                console.log(error);
            }
        }
        getMyCouponsInfo();
        // console.log(JSON.parse(localStorage.getItem('user')).coupons)
    },[accessToken])
    return(
        <div id="child">
            <div className="top-section">
                <Top title="쿠폰함"></Top>
            </div>
            <SCouponSection>
                <SBodyTitle>
                    <div>보유 쿠폰 {myCoupons.length} 장</div>
                    <Link to="register" className="link" >
                        쿠폰 등록하기
                        <i style={{margin:"0px 0px 0px 3px"}} className="fa-solid fa-angle-right"></i>
                    </Link>
                </SBodyTitle>
                <SBodyContent>
                    { myCoupons?.map(myCoupon => (
                         <MyCouponCard myCoupon={myCoupon}>

                         </MyCouponCard>
                    ))}
                    <div className="knock-bottom"></div>

                </SBodyContent>
            </SCouponSection>
        </div>
    )
}

export default MyCouponPage;