import React, { useEffect, useState } from "react";
import Top from "../../components/Header/Top";
import Tabs from "../../components/Tab/Tabs";
import TabBeforeUse from "../../components/Tab/TabBeforeUse";
import { getMyInfo, getOrders } from "../../utils/api";
import TabAfterUse from "../../components/Tab/TabAfterUse";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken } from "../../redux/reducer";
import { refreshToken } from "../../utils/common";

const MyReservationPage = () => {
    const [isBefore, setIsBefore] = useState(true);
    const [isReserved, setIsReserved] = useState([]);
    const [isReserving, setIsReserving] = useState([]);
    const dispatch = useDispatch();
    const accessToken = useSelector(state=> state.auth.accessToken);
    const handleTabClick = (newValue) => {
        // 현재 상태가 이미 true이면 변경하지 않음
        if (isBefore && newValue === true) {
            return;
        }
        setIsBefore(newValue);
    };
    useEffect(()=>{
        const fetchData = async () =>{
            try {
                const data = await getOrders(accessToken);//userid 삽입
                setIsReserving(data?.result?.filter(order=>order.order_status ==="pending" || order.order_status ==="approved"));
                setIsReserved(data?.result?.filter(order=> order.order_status ==="completed" || order.order_status ==="canceled"));
            } catch (error) {
                console.error("데이터를 가져오는 중 오류 발생", error);
                switch(error) {
                    case 'AUTH_TOKEN_EXPIRED':
                        console.log('토큰 만기로 토큰을 refresh 하겠습니다. ');
                        dispatch(setAccessToken(await refreshToken()));
                        break;
                    case 'AUTH_CREDENTIALS_INVALID':
                        console.log('적절하지 않은 토큰(null)으로 토큰을 refresh 하겠습니다. ');
                        dispatch(setAccessToken(await refreshToken()));
                        break;
                  }      
            }
        }
        fetchData();
    },[accessToken])
    const addMultipleItemsReserving = (newItems) => {
        setIsReserving((prevArray) => [...prevArray, ...newItems]);
      };
    const addMultipleItemsReserved = (newItems) => {
    setIsReserved((prevArray) => [...prevArray, ...newItems]);
    };
    // useEffect(()=>{
    //     const fetchMe = async () =>{
    //         try {
    //             const data = await getMyInfo(accessToken);
    //             if(data.ret) {
    //                 console.log(data.ret);
    //                 setIsReserving(data.ret.orders.approved);
    //                 addMultipleItemsReserving(data.ret.orders.pending);
    //                 setIsReserved(data.ret.orders.rejected);
    //                 addMultipleItemsReserved(data.ret.orders.completed);
    //             }
    //             else {
    //                 /* 로그 코드*/
    //                 console.log("다음과 같은 에러가 있습니다. " + data.error_code);
                    
    //                 switch(data.error_code) {
    //                     case 'AUTH_TOKEN_EXPIRED':
    //                         console.log('토큰 만기로 토큰을 refresh 하겠습니다. ');
    //                         dispatch(setAccessToken(await refreshToken()));
    //                         break;
    //                     case 'AUTH_CREDENTIALS_INVALID':
    //                         console.log('적절하지 않은 토큰(null)으로 토큰을 refresh 하겠습니다. ');
    //                         dispatch(setAccessToken(await refreshToken()));
    //                         break;
    //                   }       
    //             }
    //         } catch (error) {
    //             console.log("예상치 못한 에러입니다.");
    //             console.error(error);
    //         }
    //     }
    //     fetchMe();
    // },[accessToken])
    return(
        <div id="child">
            <div className="my-reservation">
                <div className="top-section">
                    <Top title="내 예약 정보"></Top>
                </div>
                <div>
                    <div className="button-wrapper">
                        <div 
                            className={`button-tap two-button ${isBefore === true ? "clicked" : ""}`}
                            onClick={()=>handleTabClick(true)}>진행 중</div>
                        <div 
                            className={`button-tap two-button ${isBefore === false ? "clicked" : ""}`}
                            onClick={()=>handleTabClick(false)}>예약 내역</div>
                    </div>
                    {isBefore === true ? <TabBeforeUse orders={isReserving}></TabBeforeUse >: <TabAfterUse orders={isReserved}></TabAfterUse>}
                </div>
                <div className="knock-bottom2"/>
            </div>

        </div>
    )
}  

export default MyReservationPage;