import React, { useEffect, useState } from "react";
import { SCouponWrapper, SFlex } from "./styles";
import Modal from "../../components/Modal/Modal";
import { getMyCoupon, refreshAccessToken } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { setAccessToken } from "../../redux/reducer";
import { refreshToken } from "../../utils/common";
import styled from "styled-components";
import { SCardBodyBottom, SCardBodyTop } from "../Coupon/styles";
import { formatCurrency } from "../../utils/formatter";

const MyReservationCoupon = ({ selectedCouponCost, selectedValue, setSelectedValue}) =>{
    const [isModal, setIsModal] = useState(false);
    const [myCoupons, setMyCoupons] = useState([]);
    const dispatch = useDispatch();
    const accessToken = useSelector(state=> state.auth.accessToken);
    const [myCoupon, setMyCoupon] = useState(0);

    const handleCouponChange = (event) => {
      setMyCoupon(event.target.value);
    };
    const fetchMyCoupon = async() =>{
        try {
            const data = await getMyCoupon(accessToken);
            if(data.ret){
                setMyCoupons(data.ret);
            }
            else {
                /* 로그 코드*/
                console.log("다음과 같은 에러가 있습니다. " + data.error_code);
                
                switch(data.error_code) {
                    case 'AUTH_TOKEN_EXPIRED':
                        console.log('토큰 만기로 토큰을 refresh 하겠습니다. ');
                        dispatch(setAccessToken(await refreshToken()));
                        break;
                    case 'AUTH_CREDENTIALS_INVALID':
                        console.log('적절하지 않은 토큰(null)으로 토큰을 refresh 하겠습니다. ');
                        dispatch(setAccessToken(await refreshToken()));
                        break;
                  }       
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(()=>{
        fetchMyCoupon();
    },[])
    return(
        <>
            <SCouponWrapper>
                <SFlex>
                    <div>
                        쿠폰 할인
                    </div>
                    <div onClick={()=> setIsModal(true)} className="cursor-pointer">
                        {selectedValue || '선택 가능'}
                    </div>
                </SFlex>
        
            </SCouponWrapper>
            { isModal && 
                <Modal 
                    isOpen={isModal}
                    height="100vh"
                    backgroundColor="rgb(0 0 0 / 68%);"
                    justifyContent="center">

                    {/* 모달 내용을 감싸는 박스 */}
                    <div className="modal-box">

                        {/* 모달 닫기 버튼 */}
                        <div className="modal-box-close">
                        <i onClick={() => setIsModal(false)} className="fa-solid fa-xmark cursor-pointer"></i>
                        </div>
                        <div>
                            {myCoupons.length == 0 && <>쿠폰 없음</>}
                            {myCoupons.map((coupon)=>
                                (
                                    <SCouponBox key = {coupon.coupon_id} className="cursor-pointer">
                                        <label>
                                            <input 
                                                type="radio" 
                                                name="coupon" 
                                                value={coupon.coupon_id}
                                                onChange={(e)=>{
                                                    handleCouponChange(e);
                                                    setSelectedValue(coupon.coupon_name);
                                                    selectedCouponCost(coupon.coupon_discount_amount);
                                                }}
                                                checked={coupon.coupon_id === Number(myCoupon)}
                                            />
                                            {coupon.coupon_name}
                                        </label>
                                        <SCardContainer
                                          onClick={() => {
                                            // 라디오 버튼 클릭 시에는 변경할 필요가 없으므로 무시
                                            if (document.activeElement.tagName.toLowerCase() !== 'input') {
                                                // 라디오 버튼이 아닌 부분을 클릭했을 때 라디오 버튼을 선택하도록 처리
                                                const radioInput = document.querySelector(`input[value="${coupon.coupon_id}"]`);
                                                if (radioInput) {
                                                    radioInput.click();
                                                }
                                            }
                                        }}>
                                           <SCardHeader>
                                                {coupon.coupon_name}
                                            </SCardHeader>
                                            <SCardBodyTop>
                                                {formatCurrency(coupon.coupon_discount_amount)}원
                                            </SCardBodyTop>
                                            <SCardBodyBottom>
                                                ~ {coupon.expired_at}
                                            </SCardBodyBottom>
                                        </SCardContainer>
                                    </SCouponBox>
                                )
                            )}
                            <SFixButtonCenter onClick={()=> setIsModal(false)} >사용하기</SFixButtonCenter>


                        </div>
                        {/* 자식 컴포넌트를 모달 박스 내에 렌더링 */}
                    </div>
                </Modal>
            }
          
        </>
       
    )
}
 const SCardContainer = styled.div`
    width:100%;
    background-color:#fff;
    border-radius:20px 20px 20px 20px;
    border:1px solid #333;
    margin:20px 0px;
`
 const SCardHeader= styled.div`
    padding:10px 20px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-bottom:1px solid #f1f1f1;
`;

const SCouponBox = styled.div`
    text-align:left;
`
const SFixButtonCenter = styled.div`
    padding:10px 20px;
    background-color:#00B6F1;
    color:#fff;
    border-radius:10px;
    font-weight:600;
    position: fixed;
    bottom: 70px; /* 원하는 거리 조정 */
    right: 50%; /* 화면 가로 중앙에 위치 */
    transform: translateX(50%); /* 화면 가로 중앙에 위치 */
    z-index: 999; /* 다른 요소 위에 레이어를 정의합니다. */
`
export default MyReservationCoupon;