import React, { useState } from "react";
import styled from "styled-components";
import Top from "../../components/Header/Top";
import { SBodyContent, SBodyTitle, SContentInput, SContentInputButton, SContentInputWrapper, SCouponSection } from "./styles";
import { refreshAccessToken, setMyCoupon } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken } from "../../redux/reducer";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const RegisterCoupon = () => {
    const [alertMsg, setAlertMsg] = useState('');
    const [couponCode, setCouponCode] = useState(''); 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(state=> state.auth.accessToken);
    const refreshToken = async() => {
        const refreshToken = Cookies.get('refreshToken');
        const idToken = Cookies.get('idToken');
        try {
            const newToken = await refreshAccessToken({accessToken:'', refreshToken, idToken})
            dispatch(setAccessToken(newToken.access_token));
        } catch (error) {
            switch(error.detail[0].msg) {
                case 'Input should be a valid string':
                    console.log('타당하지 않은 요청값이 있습니다.');
                    break;
            }
        }
    }
    const setCouponNumber = async () =>{
        try {
            const data = await setMyCoupon(couponCode,accessToken);
            if(data.ret){
                alert("쿠폰이 정상적으로 입력되었습니다.");
                navigate('/mypage/myCoupon');
                console.log("쿠폰을 정상적으로 등록하였습니다.");
            }else{
                /* 로그 코드 */
                alert('에러가 발생하였습니다. 다시 입력해주세요. 똑같은 문제가 발생 시 관리자에게 문의해주세요.')
                console.log("쿠폰 등록에 다음과 같은 에러가 있습니다. " + data.error_code);
                switch(data.error_code){
                    case 'AUTH_TOKEN_EXPIRED':
                        console.log('토큰 만기로 토큰을 refresh 하겠습니다. ');
                        refreshToken();
                        break;
                    case 'AUTH_CREDENTIALS_INVALID':
                        console.log('적절하지 않은 토큰(null)으로 토큰을 refresh 하겠습니다. ');
                        refreshToken();
                        break;
                    case 'COUPON_ALREADY_ASSIGNED':
                        console.log('이미 발급된 쿠폰입니다.');
                        alert('이미 발급된 쿠폰입니다.');
                        break;
                }

            }
            // alert( "쿠폰이 정상적으로 등록되었습니다.");
        } catch (error) {
            console.log(error);
        }
    }
    return(
        <div id="child">
            <div className="top-section">
                <Top title="쿠폰 등록하기"></Top>
            </div>
            <SCouponSection>
                <SBodyTitle>
                    <div>쿠폰 번호 입력</div>
                </SBodyTitle>
                <SBodyContent>
                    <SContentInputWrapper>
                        <SContentInput
                            placeholder="일련 번호를 입력하세요."
                            onChange={(e)=>{setCouponCode(e.target.value)}}
                            >

                        </SContentInput>
                        <SAlertCaption>
                            {alertMsg}
                        </SAlertCaption>
                        <SContentInputButton 
                            className="button-cta"
                            onClick={setCouponNumber}
                            >
                            등록하기
                        </SContentInputButton>
                    </SContentInputWrapper>
                </SBodyContent>
             </SCouponSection>
        </div>
    )
}
const SAlertCaption = styled.div`
    color:red;
    font-size:12px;
`;


export default RegisterCoupon;