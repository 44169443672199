import React from 'react';
import styled from 'styled-components';
function LoadingScreen() {
  return (
    <SLoadingScreen className="loading-screen">
      <SLoader className="loader"></SLoader>
      <p>Loading...</p>
    </SLoadingScreen>
  );
}
const SLoadingScreen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const SLoader = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
`;
export default LoadingScreen;
