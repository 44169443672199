import React, { useEffect, useState } from "react";
import LogoImg from "../../assets/main-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../config/firebase";
import { browserLocalPersistence, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../../redux/reducer";
import Cookies from "js-cookie";
import { SButton, SInput, SInputCaption, SInputContainer, SLoginSection, SLogoImg, SRegisterLink } from "./styles";
import { getAccessToken, healthCheck } from "../../utils/api";
import { checkEmail, checkPw } from "../../utils/common";
import LoadingScreen from "../../components/Common/Loading";
import { Snackbar } from "@mui/material";
const Login = () => {
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const myDataFromState = location.state?.snackBar;

    const generateToken = async (user) => {
        try {
            const res = await getAccessToken(user.accessToken);
            dispatch(setAccessToken(res.access_token));
            Cookies.set('refreshToken', res.refresh_token)
        } catch (error) {
            console.log(error);
            setErrMsg("토큰 발급에 실패하였습니다. 이메일 인증을 먼저 진행해주세요. 이후에도 안된다면 노크노크 관리자에게 문의 바랍니다.")
            firebaseAuth.signOut();
            throw error;
        }
    }
    const checkInfo = () =>{
        if(checkEmail(userEmail)!=='') setErrMsg(checkEmail(userEmail));
        else if(checkPw(userPassword, userPassword) !== '') setErrMsg(checkPw(userPassword, userPassword));
        else{
            setErrMsg('');
            return 1;
            // login(); // Enter 입력이 되면 클릭 이벤트 실행
        }
        return 0;
    }
    const login = async () => {
        if (checkInfo()) {
            setIsLoading(true);
            try {
                setPersistence(firebaseAuth, browserLocalPersistence)
                    .then(() => signInWithEmailAndPassword(firebaseAuth, userEmail, userPassword))
                    .then(async (userCredential) => {
                        if (userCredential) {
                            try {
                                await generateToken(userCredential.user);
                            } catch (error) {
                                console.log(error);
                                throw error;
                            }
                        } else {
                            alert("다시 로그인을 시도해주세요.");
                        }
                    })
                    .then(() => {
                        setIsLoading(false);
                    })
                    .then(()=>{
                        navigate('/', { state: { snackBar: true } });
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                        const errorCode = error.code;
                        switch (errorCode) {
                            case 'auth/user-not-found':
                                setErrMsg('입력한 아이디는 없는 아이디입니다.');
                                break;
                            case 'auth/wrong-password':
                                setErrMsg('비밀번호가 일치하지 않습니다.');
                                break;
                            case 'auth/invalid-login-credentials':
                                setErrMsg("아이디 혹은 비밀번호 정보를 정확하지 않습니다.");
                                break;
                            default:
                                setErrMsg("로그인에 실패하였습니다. 이메일 인증을 진행했는지 확인해주세요.");
                        }
                    });
            } catch (err) {
                console.log(err.code);
                alert("로그인에 실패하였습니다.");
                setUserEmail('');
                setUserPassword('');
                setIsLoading(false);
            }
        }
    }
    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            if(checkInfo()) login();
        }
    };
    useEffect(()=>{
        healthCheck();
        setOpen(myDataFromState)
    },[])
    return(
        <div id="child">

            <Snackbar 
                open={open} 
                onClose={() => setOpen(false)} 
                message="회원가입에 성공하였습니다. 가입하신 이메일로 이동하셔서 인증을 하셔야 정상적으로 로그인 할 수 있습니다."
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            />
            {isLoading ? <LoadingScreen></LoadingScreen> : 
            
                <SLoginSection>
                    <SLogoImg src={LogoImg}></SLogoImg>
                    <SInputContainer>
                        <SInput 
                            type="email" 
                            placeholder="e.g. knock@knock.co.kr"
                            value={userEmail}
                            onChange={(e) => {
                                setUserEmail(e.target.value);
                            }}
                            name="email">
                        </SInput>
                        <div>
                            <SInput 
                                type="password" 
                                placeholder="********"
                                value={userPassword}
                                onChange={(e) => {
                                    setUserPassword(e.target.value);
                                }}
                                onKeyDown={handleOnKeyPress}
                                name="password"
                            />
                        
                            <SInputCaption>{errMsg}</SInputCaption>
                        </div>
                    
                        <SButton onClick={()=>login()}>로그인</SButton>
                        <SRegisterLink to="/signup">회원가입 하기</SRegisterLink>
                    </SInputContainer>

                </SLoginSection>
            }
        </div>
    )
}

export default Login;