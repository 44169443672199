import React from "react";
import RoomCard from "../Card/RoomCard";

const TabContent1 = (hotel)=>{
    const hotelInfo = hotel?.hotel[0];
    return(
        <>
            {
                hotelInfo?.rooms?.map((room) =>  (<RoomCard hotel={hotelInfo} room={room}  key={room.room_id}></RoomCard>))
            }
        </>
    )
}

export default TabContent1;