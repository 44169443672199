import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import reducer from "./reducer";
import logger from 'redux-logger'; // redux-logger 미들웨어 불러오기

const store = configureStore({
    reducer:{
        auth : reducer,
    },
    middleware : (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store;
