import React from "react";
import { SInfoWrapper, SInfoTitle, SInfoFlexTwo, SInfoFlex, SInfoLabel, SInfoCaption, SInfoAlert } from "./styles";
import { formatCntDay, formatCurrency } from "../../utils/formatter";

const ReservationInfoSection = ({order}) => {    
    return(
        <SInfoWrapper>
            <SInfoTitle>
               {order.accommodation_name}
            </SInfoTitle>
            <SInfoFlex>
                <SInfoFlexTwo>
                    <SInfoLabel>
                        체크인
                    </SInfoLabel>
                    <SInfoCaption>
                        {order.order_check_in}
                    </SInfoCaption>
                </SInfoFlexTwo>
                <SInfoFlexTwo>
                    <SInfoLabel>
                        체크아웃
                    </SInfoLabel>
                    <SInfoCaption>
                        {order.order_check_out}
                    </SInfoCaption>
                </SInfoFlexTwo>
            </SInfoFlex>
            <SInfoFlex>
                <SInfoFlexTwo>
                    <SInfoLabel>
                        인원
                    </SInfoLabel>
                    <SInfoCaption>
                        {order.order_total_guest}
                    </SInfoCaption> 
                </SInfoFlexTwo>
            </SInfoFlex>
            <SInfoFlex>
                <SInfoFlexTwo>
                    <SInfoLabel>
                        숙박
                    </SInfoLabel>
                    <SInfoCaption>
                        {formatCntDay(order.order_check_in, order.order_check_out, null)}박 / {formatCurrency(order.total_cost * formatCntDay(order.order_check_in, order.order_check_out, null))}원
                    </SInfoCaption>
                </SInfoFlexTwo>
            </SInfoFlex>
            <SInfoAlert>
             체크인 D-1까지 무료취소 가능
            </SInfoAlert>
        </SInfoWrapper>
    )
}

export default ReservationInfoSection;