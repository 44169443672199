import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = ({position}) => {
       return(
        <SFooterContainer>
            <SFooter position={position}>
                {/* <SFooterLogo src={KnockLogo}></SFooterLogo> */}
                <div>
                    <strong>회사명 </strong>
                    주식회사 베이띵스
                    <SDivider>|</SDivider> <strong> 대표자 </strong>
                    김동낙
                </div>
                <div>
                    <strong>대표메일  </strong>
                    knock@baithings.co.kr 
                    <SDivider>|</SDivider><strong>전화번호 </strong>
                    070-8064-6905
                </div>
                <div>       
                    <strong>사업자등록번호 </strong> 492-88-02447
                    <SDivider>|</SDivider> 
                    <strong> 통신판매업신고 </strong>
                    <Link className='link underline' to='http://www.ftc.go.kr/bizCommPop.do?wrkr_no=4928802447'>제2023-경기안산-1909호</Link>
                </div>
                <div>
                    <strong>사업소재지 </strong>
                    경기도 안산시 상록구 한양대학로 55, 창업보육센터 425호
                </div>

                <div>
                    <Link to="https://baithings.co.kr"className="link">공식 홈페이지</Link><SDivider>|</SDivider>
                    <Link to="https://blog.naver.com/baithings" className="link">블로그</Link><SDivider>|</SDivider>
                    <Link to="https://www.instagram.com/knock.wiki/"className="link">SNS </Link><SDivider>|</SDivider>
                    <Link to="/terms" className="link">서비스 이용약관</Link><SDivider>|</SDivider>
                    <Link to="/policy"className="link">개인정보처리방침 </Link><SDivider>|</SDivider>
                </div>
            </SFooter>
        </SFooterContainer>
    )
}
const SFooterContainer = styled.div`
    background-color: #e7e7e7;
`;

const SFooter = styled.footer`
    position:${(props)=> (props.position)};
    padding: 10px;
    line-height:28px;
    font-size: 12px;
    background-color:#F7F7F7;
    max-width: 480px;
    width:calc(100% - 20px);
    margin: 0px auto;
    bottom: 0;

`;
const SDivider = styled.strong`
    color:#b8b8b8;
    margin:0px 2px;
`
export default Footer;