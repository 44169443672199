import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    accessToken: null, // 초기 accessToken 값
}

const authSlice = createSlice({
    name : 'auth',
    initialState,
    reducers: {
        setAccessToken : (state, action) =>{
            state.accessToken = action.payload;
        },
        clearAccessToken : (state) => {
            state.accessToken = null;
        }
    }
})

export const { setAccessToken, clearAccessToken } = authSlice.actions;
export default authSlice.reducer;