import React, { useEffect } from "react";
import { SInfoWrapper, SInfoFlex, SInfoLabel, SInfoCaption, STotalCost } from "./styles";
import { formatCurrency } from "../../utils/formatter";

const ReservationPaymentSection = ({totalCost, couponCost, setCost}) => {
    useEffect(()=>{
        if(totalCost > couponCost){
            setCost((totalCost)-couponCost)
        } else setCost(0);
    },[totalCost, couponCost])
    
    return(
        <SInfoWrapper>
             <SInfoFlex>
                <SInfoLabel>상품 금액</SInfoLabel>
                <SInfoCaption> {formatCurrency(totalCost)}원</SInfoCaption>
            </SInfoFlex>
            <SInfoFlex>
                <SInfoLabel>할인 금액</SInfoLabel>
                <SInfoCaption>- {formatCurrency(couponCost)}원</SInfoCaption>
            </SInfoFlex>
            <SInfoFlex>
                <SInfoLabel>총 결제 금액</SInfoLabel>
                <STotalCost>{( (totalCost - couponCost) < 0 ? <>0</> : <>{formatCurrency(totalCost - couponCost)}</>)}원</STotalCost>
            </SInfoFlex>
        </SInfoWrapper>
    )
}

export default ReservationPaymentSection;