import React from "react";
import { useNavigate } from "react-router-dom";

const FailurePage = () => {
    const navigate = useNavigate();
    const move = () =>{
        navigate('/');
    }
    return(
    <>
        <div id="child">
            <div className="redirect-container">
                결제 실패하였습니다. 관리자에게 문의해주세요.
                <div className="button-cta redirect-button" onClick={()=> move()}>확인</div>
            </div>
        </div>
    </>
    )
}

export default FailurePage;