import React from "react";
import styled from "styled-components";
import Top from "../components/Header/Top";

const Terms = () => {
    return(
        <>
            <STopSection className="top-section">
                <Top></Top>
            </STopSection>
            <SPolicyWrapper>
            <h1>서비스이용약관</h1>

                <h2>제 1 장 총칙</h2>

                <h3>제 1 조 (목적)</h3>
                <p>본 약관은 (주)베이띵스가 운영하는 장애인이 접근할 수 있는 객실 중개 플랫폼의 제반 서비스의 이용조건 및 절차에 관한 사항 및 기타 필요한 사항을 규정함을 목적으로 한다.</p>

                <h3>제 2 조 (용어의 정의)</h3>
                <p>본 약관에서 사용하는 용어는 다음과 같이 정의한다.</p>
                <ol>
                    <li>회원 : 서비스에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 말하며, 회원계정(ID/PW)을 생성하지 않은 일반 고객(일명 비회원 고객)도 포함</li>
                    <li>판매자 : 회사가 제공하는 서비스를 이용하여 자신의 상품∙용역∙서비스 등을 판매하는 자를 의미하며, 회사로부터 예약∙판매 대행, 광고 서비스 등을 제공받는 자</li>
                    <li>아이디(ID) : 회원식별과 회원의 서비스 이용을 위해 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합</li>
                    <li>비밀번호(Password) : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합</li>
                    <li>해지 : 회사 또는 회원에 의한 이용계약의 종료</li>
                </ol>

                <h3>제 3 조 (약관의 공시 및 효력과 변경)</h3>
                <ol>
                    <li>본 약관은 회원가입 화면에 게시하여 공시하며 회사는 사정변경 및 영업상 중요한 사유가 있을 경우 약관을 변경할 수 있으며 변경된 약관은 공지사항을 통해 공시한다</li>
                    <li>본 약관 및 차후 회사사정에 따라 변경된 약관은 이용자에게 공시함으로써 효력을 발생한다.</li>
                </ol>

                <h3>제 4 조 (약관 외 준칙)</h3>
                <ol>
                    <li>본 약관 또는 개별약관에서 정하지 않은 사항은 『전기통신사업법』, 『전자문서 및 전자거래 기본법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『전자상거래 등에서의 소비자보호에 관한 법률』, 『개인정보보호법』 등 관련 법의 규정 및 회사가 정한 서비스의 세부이용지침 등의 규정에 따릅니다. 또한 본 약관에서 정한 회사의 책임 제한 규정은 관련 법령이 허용하는 최대한도의 범위 내에서 적용됩니다.</li>
                    <li>회사는 필요한 경우 서비스 내의 개별항목에 대하여 개별약관 또는 운영원칙을 정할 수 있으며, 본 약관과 개별약관 또는 운영원칙의 내용이 상충되는 경우에는 개별약관 또는 운영원칙을 의 내용이 우선 적용됩니다.</li>
                </ol>

                <h2>제 2 장 이용계약</h2>

                <h3>제 5 조 (이용신청)</h3>
                <ol>
                    <li>이용신청자가 회원가입 안내에서 본 약관과 개인정보보호정책에 동의하고 등록절차(회사의 소정 양식의 가입 신청서 작성)를 거쳐 '확인' 버튼을 누르면 이용신청을 할 수 있다.</li>
                    <li>이용신청자는 반드시 실명과 실제 정보를 사용해야 하며 1개의 생년월일에 대하여 1건의 이용신청을 할 수 있다.</li>
                    <li>실명이나 실제 정보를 입력하지 않은 이용자는 법적인 보호를 받을 수 없으며, 서비스 이용에 제한을 받을 수 있다.</li>
                </ol>

                <h3>제 6 조 (이용신청의 승낙)</h3>
                <ol>
                    <li>회사는 제5조에 따른 이용신청자에 대하여 제2항 및 제3항의 경우를 예외로 하여 서비스 이용을 승낙한다.</li>
                    <li>회사는 아래 사항에 해당하는 경우에 그 제한사유가 해소될 때까지 승낙을 유보할 수 있다.</li>
                    <ul>
                        <li>가. 서비스 관련 설비에 여유가 없는 경우</li>
                        <li>나. 기술상 지장이 있는 경우</li>
                        <li>다. 기타 회사 사정상 필요하다고 인정되는 경우</li>
                    </ul>
                    <li>회사는 아래 사항에 해당하는 경우에 승낙을 하지 않을 수 있다.</li>
                    <ul>
                        <li>가. 다른 사람의 명의를 사용하여 신청한 경우</li>
                        <li>나. 이용자 정보를 허위로 기재하여 신청한 경우</li>
                        <li>다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우</li>
                        <li>라. 기타 회사가 정한 이용신청 요건이 미비한 경우</li>
                    </ul>
                </ol>

                <h2>제 3 장 계약 당사자의 의무</h2>

                <h3>제 7 조 (회사의 의무)</h3>
                <ol>
                    <li>회사는 플랫폼을 안정적이고 지속적으로 운영할 의무가 있다.</li>
                    <li>회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우에는 즉시 처리해야 한다. 단, 즉시 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 공지사항 또는 전자우편을 통해 통보해야 한다.</li>
                    <li>제1항의 경우 수사상의 목적으로 관계기관 및 정보통신윤리위원회의 요청이 있거나 영장 제시가 있는 경우, 기타 관계 법령에 의한 경우는 예외로 한다.</li>
                </ol>

                <h3>제 8 조 (이용자의 의무)</h3>
                <ol>
                    <li>이용자는 본 약관 및 회사의 공지사항, 플랫폼 이용안내 등을 숙지하고 준수해야 하며 기타 회사의 업무에 방해되는 행위를 해서는 안된다.</li>
                    <li>이용자는 회사의 사전 승인 없이 본 플랫폼을 이용해 어떠한 영리행위도 할 수 없다.</li>
                    <li>이용자는 본 플랫폼을 통해 얻는 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 및 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없다.</li>
                </ol>

                <h2>제 4 장 서비스의 제공 및 이용</h2>

                <h3>제 9 조 (서비스 이용)</h3>
                <ol>
                    <li>이용자는 본 약관의 규정된 사항을 준수해 플랫폼을 이용한다.</li>
                    <li>본 약관에 명시되지 않은 서비스 이용에 관한 사항은 회사가 정해 '공지사항'에 게시하거나 또는 별도로 공지하는 내용에 따른다.</li>
                </ol>

                <h3>제 10 조 (정보의 제공)</h3>
                <p>회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보에 대하여 전자메일이나 서신우편 등의 방법으로 회원에게 정보를 제공할 수 있다.</p>

                <h3>제 11 조 (광고게재)</h3>
                <ol>
                    <li>회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고 등을 게재할 수 있다.</li>
                    <li>회사는 플랫폼에 게재되어 있는 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 책임을 지지 않는다.</li>
                </ol>

                <h3>제 12 조 (서비스 이용의 제한)</h3>
                <p>플랫폼 이용 및 행위가 다음 각 항에 해당하는 경우 회사는 해당 이용자의 이용을 제한할 수 있다.</p>
                <ol>
                    <li>공공질서 및 미풍양속, 기타 사회질서를 해하는 경우</li>
                    <li>범죄행위를 목적으로 하거나 기타 범죄행위와 관련된다고 객관적으로 인정되는 경우</li>
                    <li>타인의 명예를 손상시키거나 타인의 서비스 이용을 현저히 저해하는 경우</li>
                    <li>타인의 의사에 반하는 내용이나 광고성 정보 등을 지속적으로 전송하는 경우</li>
                    <li>해킹 및 컴퓨터 바이러스 유포 등으로 서비스의 건전한 운영을 저해하는 경우</li>
                    <li>다른 이용자 또는 제3자의 지적재산권을 침해하거나 지적재산권자가 지적 재산권의 침해를 주장할 수 있다고 판단되는 경우</li>
                    <li>타인의 아이디 및 비밀번호를 도용한 경우</li>
                    <li>기타 관계 법령에 위배되는 경우 및 회사가 이용자로서 부적당하다고 판단한 경우</li>
                </ol>

                <h3>제 13 조 (서비스 제공의 중지)</h3>
                <p>회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부의 제공을 중지할 수 있다.</p>
                <ol>
                    <li>전기통신사업법 상에 규정된 기간통신 사업자 또는 인터넷 망 사업자가 서비스를 중지했을 경우</li>
                    <li>정전으로 서비스 제공이 불가능할 경우</li>
                    <li>설비의 이전, 보수 또는 공사로 인해 부득이한 경우</li>
                    <li>서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 어려운 경우</li>
                    <li>기타 천재지변 또는 회사가 통제할 수 없는 사유로 인해 서비스 제공이 어려운 경우</li>
                </ol>

                <h2>재 5장 회사 및 회원의 의무</h2>

                <h3>제 14조 (회사의 의무)</h3>
                <ol>
                    <li>회사는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력해야 한다.</li>
                    <li>회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수하며, 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여 노력해야 한다.</li>
                    <li>회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 그에 필요한 조치를 취하여야 한다.</li>
                    <li>회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 과실에 의해 발생한 경우에 한하여 회사에서 책임을 부담해야 한다.</li>
                </ol>

                <h3>제 15조 (회원의 의무)</h3>
                <ol>
                    <li>회원은 기타 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 된다.</li>
                    <li>회원은 서비스의 이용권한, 기타 서비스 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없다.</li>
                </ol>

                <h3>제 16 조 (서비스 이용 제한)</h3>
                <ol>
                    <li>회사는 이용제한정책에서 정하는 바에 따라 회원이 본 약관상 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고∙일시적 이용정지∙영구적 이용정지 등의 단계로 서비스 이용을 제한하거나 이용계약을 해지할 수 있다.</li>
                    <li>회사는 회원이 계속해서 1년 이상 서비스를 이용하지 않은 경우에는, 정보통신망법에 따라 필요한 조치를 취할 수 있고, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있다.</li>
                    <li>회원은 본조에 따른 서비스 이용정지 기타 서비스 이용과 관련된 이용제한에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있으며, 회사는 회원의 이의신청이 정당하다고 판단되는 경우 즉시 서비스 이용을 재개합니다.</li>
                </ol>

                <h2>재 6장 기타</h2>

                <h3>제 17조 (손해 배상)</h3>
                <ol>
                    <li>회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 본 약관을 위반한 회원은 회사에 발생하는 손해를 배상하여야 한다.</li>
                    <li>회원이 서비스를 이용하는 과정에서 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상청구 또는 기타의 항의를 받는 경우, 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 한다.</li>
                </ol>

                <h3>제 18조 (면책 조항)</h3>
                <ol>
                    <li>회사는 천재지변, 전쟁, 내란, 노동쟁의, 정부의 명령, 전기통신사업자의 서비스 중지, 기타 회사가 통제할 수 없는 사유로 인한 서비스 중지 또는 장애의 경우에 서비스의 중지 또는 장애에 따른 손해에 대하여 책임을 부담하지 아니한다.</li>
                    <li>회사는 회원이나 제3자에 의해 게재, 전송, 입력 또는 기타 방법으로 제공된 정보, 자료, 사실의 진실성, 정확성 등 내용에 대하여는 책임을 부담하지 않으며, 회원 간의 상거래에 대하여도 어떠한 책임도 부담하지 않는다.</li>
                    <li>회사는 회원의 귀책사유로 인한 서비스 이용의 장애, 손실 또는 손해에 대하여는 책임을 부담하지 않는다.</li>
                    <li>회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대하여는 책임을 부담하지 않는다.</li>
                </ol>

                <h3>제 19조 (재판권 및 준거법)</h3>
                <ol>
                    <li>회사와 회원 간 제기된 소송은 대한민국 법을 준거법으로 한다.</li>
                    <li>회사와 회원간 발생한 분쟁에 관한 소송은 서울중앙지방법원을 전속적 관할법원으로 한다.</li>
                </ol>

                <h3>제 20조 (통지 및 고지)</h3>
                <p>회사는 회원에게 필요한 경우 전자우편, 서신우편, SMS, 전화 등의 방법으로 통지한다. 회사의 주소나 전화번호, 그 밖의 연락처가 변경된 경우에는 이를 알려야 한다.</p>

                <h3>제 21조 (서비스 약관 변경)</h3>
                <ol>
                    <li>회사는 이 약관의 규정을 변경할 수 있다.</li>
                    <li>회사는 변경된 약관을 사이트에 공지하며, 회원에게는 이메일, SMS 등의 방법으로 통보한다.</li>
                </ol>

                <p class="right-aligned">본 약관은 2023년 9월 21일부터 적용됩니다.</p>
        </SPolicyWrapper>
        </>
        
    )
}
const SPolicyWrapper = styled.div`
    background-color:white;
    padding:10px;
`;
const STopSection = styled.div`
    background-color:white;
`;
export default Terms;