import React from "react";
import '../styles/policy.css';
import styled from "styled-components";
import Top from "../components/Header/Top";
const Policy = () => {
    return(
        <>
        <STopSection className="top-section">
            <Top></Top>
        </STopSection>
        
        <SPolicyWrapper>
            <h1>개인정보 처리방침</h1>

            <p>주식회사 베이띵스(이하 "회사"라 함) 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p>

            <h2>개인정보의 처리목적</h2>
            <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
            <table border="1">
                <thead>
                    <tr>
                        <th>분류</th>
                        <th>수집・이용 동의 목적</th>
                        <th>수집・이용 항목</th>
                        <th>보유・이용기간</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>필수</td>
                        <td>회원 가입, 계약의 이행 및 서비스 제공,  예약・구매・관심상품 내역, 결제대금의 청구, 상담・불만・민원처리, 고지・안내사항 전달, 상품・서비스 이용실적 정보 통계・분석, 상품・서비스 개선 및 추천, 불법・부정이용 방지</td>
                        <td>일반 계정을 통한 서비스 이용시 ・ 아이디(이메일), 비밀번호(이메일 회원만 수집), 닉네임, 휴대폰 번호  SNS 계정을 통한 서비스 이용시 ・ 카카오계정(이메일), 카카오계정(전화번호), CI(연계정보) ・ 네이버 계정(이메일), 휴대전화 번호  예약・구매시 ・ 공통 - 예약내역(예약일시, 결제금액, 업체명) ・ 예약자 및 구매자 - 이름, 휴대폰 번호, (필요한 서비스의 경우)생년월일 ・ 예약자와 방문자가 다른 경우 - 방문자 및 탑승자의 이름, 휴대폰 번호, (필요한 서비스의 경우)생년월일 ・ 예약확인서 발급 시 - 예약자의 이메일 주소 현금영수증 발급시  ・휴대전화번호, 이메일  현금 환불 요청 시 ・ 계좌번호 및 예금주명  고객상담 시 ・ 상담내용 및 상담에 필요한 개인정보</td>
                        <td>회원 탈퇴 요청 시 상담 등 민원처리, 정산 및 환불 처리를 위해 30일 후 삭제</td>
                    </tr>
                    <tr>
                        <td>선택</td>
                        <td>개인맞춤형 상품∙서비스 혜택에 관한 정보제공, 통계∙분석∙이용내역 정보와의 결합 및 분석</td>
                        <td>예약내역(예약일시, 결제금액, 업체명), 디바이스 ID, 휴대폰 번호, 서비스이용기록, IP주소, 접속로그, Cookie, 광고식별자, 단말기 OS와 버전, 단말기 모델명, 브라우저 버전, 예약자 및 구매자 정보(이름, 휴대폰 번호, 생년월일, 이메일 주소), 방문자 및 탑승자 정보(이름, 휴대폰 번호, 생년월일, 영문이름, 자녀의 나이), 상담내용 및 상담에 필요한 개인정보</td>
                        <td>동의 철회, 이용종료일 도래 시 까지</td>
                    </tr>
                </tbody>
            </table>

            <h2>개인정보의 제3자 제공</h2>
            <p>회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.</p>

            <p>회사는 원할한 서비스 제공을 위하여 다음의 경우 이용자의 동의를 얻어 개인정보를 제공하고 있습니다.</p>
            <table border="1">
                <thead>
                    <tr>
                        <th>제공받는 자</th>
                        <th>제공 목적</th>
                        <th>제공 항목</th>
                        <th>보유 및 이용기간</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>숙박서비스 제공업체</td>
                        <td>숙박예약서비스 이용계약 이행(서비스 제공확인 / 이용자 정보 확인)</td>
                        <td>예약번호, 예약자명,예약자 휴대폰 번호</td>
                        <td>예약서비스 제공 완료 후 6개월</td>
                    </tr>
                </tbody>
            </table>
            
            <p>다음의 경우는 예외적으로 이용자의 사전 동의 없이 이용자 정보를 제공할 수 있습니다.</p>
            <ol>
                <li>법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독 당국의 요구가 있는 경우</li>
                <li>요금 정산을 위하여 필요한 경우</li>
            </ol>

            <h2>개인정보 처리의 위탁</h2>
            <p>회사는 원할한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
            <table border="1">
                <thead>
                    <tr>
                        <th>수탁자</th>
                        <th>위탁업무</th>
                        <th>개인정보 보유 및 이용기간</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Amazon Web Services, Inc</td>
                        <td>클라우드 인프라 관리</td>
                        <td>회원 탈퇴 시 또는 위탁 계약 종료시까지</td>
                    </tr>
                </tbody>
            </table>

            <h2>개인정보의 파기절차 및 파기방법</h2>
            <ol>
                <li>회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
                <li>정보 주체로부터 동의 받은 개인정보 보유 기간이 경과하거나 처리 목적이 달성 되었음 에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인 정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.</li>
                <li>개인정보 파기의 절차 및 방법은 다음과 같습니다.</li>
                <ul>
                    <li>파기절차
                        <p>회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</p>
                    </li>
                    <li>파기방법
                        <p>회사는 전자적 파일 형태로 기록・저장된 개인정보는 기록을 재생할 수 없도로고 파기하며, 종이 문서에 기록 ・저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</p>
                    </li>
                    <li>내부 방침에 따라 보유하는 경우</li>
                    <table>
                        <thead>
                            <tr>
                                <th>보유 사유</th>
                                <th>보유 기간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>회사와 이용자간 민원, 소송 등 분쟁 과정 중 법률로 정한 보유기간이 경과한 경우</td>
                                <td>분재 해결 시까지 보관 후 파기</td>
                            </tr>
                            <tr>
                                <td>회사가 개별적으로 이용자의 동의를 받은 경우</td>
                                <td>동의 받은 기간까지 보관 후 파기</td>
                            </tr>
                            <tr>
                                <td>정산이 남아 있는 경우</td>
                                <td>정산 종료 시까지 보관 후 파기</td>
                            </tr>
                        </tbody>
                    </table>
                    <li>관계 법령에 따라 보유하는 경우</li>
                    <table>
                        <thead>
                            <tr>
                                <th>보유 정보</th>
                                <th>보유 근거</th>
                                <th>보유 기간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>계약 또는 청약철회 등에 관한 기록</td>
                                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                <td>5년</td>
                            </tr>
                            <tr>
                                <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                <td>5년</td>
                            </tr>
                            <tr>
                                <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                <td>3년</td>
                            </tr>
                            <tr>
                                <td>표시∙광고에 관한 기록</td>
                                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                <td>6개월</td>
                            </tr>
                            <tr>
                                <td>웹사이트 방문 기록</td>
                                <td>통신비밀보호법</td>
                                <td>3개월</td>
                            </tr>
                            <tr>
                                <td>전자금융거래에 관한 기록</td>
                                <td>전자금융거래법</td>
                                <td>5년</td>
                            </tr>
                            <tr>
                                <td>고객확인 및 의심거래보고 등에 관한 기록</td>
                                <td>특정 금융거래정보의 보고 및 이용 등에 관한 법률</td>
                                <td>5년</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
            </ol>

            <h2>이용자와 법정대리인의 권리와 의무 및 행사 방법</h2>
            <ol>
                <li>이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다. (만 14세 미만 아동에 관한 개인정보의 열람등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.)</li>
                <li>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
                <li>회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
            </ol>

            <h2>개인정보의 안정성 확보 조치에 관한 사항</h2>
            <p>회사는 이용자 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
            <ol>
                <li>관리적 조치 : 내부관리계획 수립∙시행, 정기적인 사내 교육, 자체 감사 등</li>
                <li>기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보 암호화, 보안프로그램 설치 등</li>
                <li>물리적 조치 : 전산실, 자료보관실 등의 접근통제</li>
            </ol>

            <h2>개인정보를 자동으로 수집하는 장치의 설치・운영 및 그 거부에 관한 사항</h2>
            <ol>
                <li>회사는 정보주체에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</li>
                <li>쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</li>
                <li>쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
                <li>쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</li>
                <li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생 할 수 있습니다.</li>
            </ol>

            <h2>행태정보의 수집・이용・제공 및 거부 등에 관한 사항</h2>
            <ol>
                <li>회사는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여 행태정보를 수집·이용하고 있습니다.</li>
                <li>회사는 다음과 같이 행태정보를 수집합니다.</li>
                <table>
                    <thead>
                        <tr>
                            <th>수집하는 행태 정보의 항목</th>
                            <th>행태 정보 수집 방법</th>
                            <th>행태 정보 수집 목적</th>
                            <th>행태 정보 보유 이용 기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>- 이용자의 서비스 방문 기록, 활동 로그 및 검색 이력</td>
                            <td>- 이용자의 서비스 이용 시 자동 수집 및 전송</td>
                            <td>- 이용자의 관심, 성향에 기반한 개인 맞춤형 상품추천 서비스(광고포함)를 제공</td>
                            <td>- 수집일로부터 2년 후 파기</td>
                        </tr>
                    </tbody>
                </table>
                <li>이용자는 웹브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 광고를 일괄적으로 차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수 있습니다.</li>
                <ul>
                    <li>웹브라우저를 통한 맞춤형 광고 차단/허용
                        <ol>
                            <li>인터넷 익스플로러(Windows 10용 Internet Explorer 11)
                                <ul>
                                    <li>Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택</li>
                                    <li>개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택</li>
                                </ul>
                            </li>
                            <li>Microsoft Edge
                                <ul>
                                    <li>Edge에서 오른쪽 상단 ‘…’ 표시를 클릭한 후, 설정을 클릭합니다.</li>
                                    <li>설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서 ‘추적방지’ 여부 및 수준을 선택합니다.</li>
                                    <li>InPrivate를 검색할 때 항상 “엄격” 추적 방지 사용’ 여부를 선택합니다.</li>
                                    <li>아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택합니다.</li>
                                </ul>
                            </li>
                            <li>크롬 브라우저
                                <ul>
                                    <li>Chrome에서 오른쪽 상단 ‘⋮’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.</li>
                                    <li>설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭합니다.</li>
                                    <li>쿠키 항목에 들어가서 필요한 설정을 합니다.</li>
                                </ul>
                            </li>
                            <li>사파리(Safari)
                                <ul>
                                    <li>Safari 메뉴에서 환경설정을 선택하고 개인정보 탭을 클릭합니다.</li>
                                    <li>‘쿠키 및 웹사이트 데이터’ 부분에서 원하는 옵션을 선택합니다.</li>
                                </ul>
                            </li>
                            <li>파이어폭스(Firefox)
                                <ul>
                                    <li>Firefox 메뉴를 클릭하고 옵션을 선택합니다.</li>
                                    <li>개인정보 탭을 클릭하고 기록 부분에서 원하는 사용자 설정을 선택합니다.</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                </ul>
            </ol>

            <h2>정책 변경에 대한 통지</h2>
            <p>본 개인정보 처리방침은 2023년 9월 21일에 시행되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 수정이 있을 시에는 변경사항의 시행 7일 전부터 공지사항 등을 통하여 고지할 것입니다.</p>

            <h2>문의처</h2>
            <p>회사와 개인정보 보호 관련 문의, 불만, 조언 등을 원하시는 경우에는 아래의 담당자에게 연락주시기 바랍니다.</p>
            <ul>
                <li>이름 : 김동낙</li>
                <li>직책 : 대표이사</li>
                <li>연락처 : 010-3456-0594</li>
                <li>이메일 : knock@baithings.co.kr</li>
            </ul>
        </SPolicyWrapper>
        </>
    )
}
const SPolicyWrapper = styled.div`
    background-color:white;
    padding:10px;
`;
const STopSection = styled.div`
    background-color:white;
    // height:50px;
`;
export default Policy;