import React from "react";
import { styled } from "styled-components";
import logoImg from "../../assets/main-logo.png";
import SearchIcon from "../../assets/images/google_search.svg";
import { useNavigate } from "react-router-dom";

const SImgWrapper = styled.img`
    width:50px;
`;
const SImgWrapper_icon = styled.img`
    width:24px;
    margin-right:10px;
    cursor:pointer;
`;
const SKakaoWrapper = styled.div`
    margin-left:10px;
`;
const Logo = () =>{
    const navigate = useNavigate();
    const moveSearch = () => {
        navigate('/search')
    }
    return(
        <>
            <SKakaoWrapper>
                {/* <a id="chat-channel-button" href="javascript:chatChannel()">
                    <SImgWrapper src={kakaoConsultImg} alt="카카오톡 채널 채팅하기 버튼" />
                </a> */}
            </SKakaoWrapper>            
            <SImgWrapper alt="knock_space_banner" src={logoImg}/>
            <SImgWrapper_icon src={SearchIcon} onClick={moveSearch}></SImgWrapper_icon>
        </>

    )
}


export default Logo;