import React from "react";

import styled from "styled-components";

const TabContent2 = (hotel)=>{
    const hotelInfo = hotel.hotel[0];
    return(
        <STabInfo>
            <STabTitle> 시설 및 서비스 </STabTitle>
            <STabInfoRowWrapper>
            {
                hotelInfo.hotel_info &&
                hotelInfo.hotel_info.map((info, index) => (
                   <>
                        <div key={'hotelInfo' + index}>
                            {info}
                        </div>
                        {hotelInfo.hotel_info.length !== (index +1) ? <STabColumnDivider>|</STabColumnDivider> : <></>}
                    </>
                ))
                
            }
            </STabInfoRowWrapper>
            <STabTitle> 주변 정보 </STabTitle>
            <STabInfoRowWrapper>
            { 
                hotelInfo.hotel_transport && 
                hotelInfo.hotel_transport.map((value, index) => (
                    <>
                        <div key={'transport' + index}>
                            {value}
                        </div>
                        {hotelInfo.hotel_transport.length !== (index +1) ? <STabColumnDivider>|</STabColumnDivider> : <></>}
                    </>

                ))
            }
            </STabInfoRowWrapper>
            <STabTitle> 부대 시설 정보 </STabTitle>
            <STabInfoColumnWrapper>
            { 
                hotelInfo.hotel_facility && 
                hotelInfo.hotel_facility.map((value, index) => (
                    <>
                        <div key={'facility' + index}>
                            {value}
                        </div>
                    </>

                ))
            }
            </STabInfoColumnWrapper>
            <STabTitle> 조식 정보 </STabTitle>
            <STabInfoColumnWrapper>
            { 
                hotelInfo.hotel_breakfast && 
                hotelInfo.hotel_breakfast.map((value, index) => (
                    <>
                        <div key={'breakfast'+index}>
                            {value}
                        </div>
                    </>

                ))
            }
            </STabInfoColumnWrapper>
            <STabTitle> 취소 및 환불 규정 </STabTitle>
            <STabInfoColumnWrapper>
            { 
                hotelInfo.hotel_refund_policy && 
                hotelInfo.hotel_refund_policy.map((value, index) => (
                    <>
                        <div key={'refund' + index}>
                            {value}
                        </div>
                    </>

                ))
            }
            </STabInfoColumnWrapper>
            <STabTitle> 확인사항 및 기타 </STabTitle>
            <STabInfoColumnWrapper>
            { 
                hotelInfo.hotel_policy && 
                hotelInfo.hotel_policy.map((value, index) => (
                    <>
                        <div key={'policy'+index}>
                            {value}
                        </div>
                    </>

                ))
            }
            </STabInfoColumnWrapper>
        </STabInfo>
    )
}
const STabInfo = styled.div`
    background-color:white;
    border-radius:10px;
    padding:20px 20px;
    margin: 20px 0px;
`
const STabTitle = styled.div`
    font-weight:700;
    font-size:18px;
    margin:10px 0px;
`;
const STabInfoRowWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px 5px;
    color:#7b7b7b;
    margin-bottom:20px;
`;
const STabInfoColumnWrapper = styled.div`
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    justify-content: flex-start;
    color:#7b7b7b;
    margin-bottom:20px;
    gap: 5px 5px;

`;
const STabColumnDivider = styled.div`
    color:#ccc;
`
export default TabContent2;