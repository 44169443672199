import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CarouselWrapper from "../Carousel/CarouselWrapper";
import NextIcon from "../../assets/images/next_icon.svg";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { generatedformatCurrency } from "../../utils/generatedOrderId";
import { createOrder, getPrices, healthCheck } from "../../utils/api";
import Modal from "../Modal/Modal";
import { SInput, SInputWrapper } from "../Input/styles/input";
import Calender from "../Calender/Calender"
import { FormControl, InputLabel, MenuItem, NativeSelect, Select } from "@mui/material";
import { changeToKR } from "../../utils/formatter";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken } from "../../redux/reducer";
import { refreshToken } from "../../utils/common";

const RoomCard = (props) =>{
    const [isModal, setIsModal] = useState(false);
    const [visitorCnt, setVisitorCnt] = useState(2)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [priceId, setPriceId] = useState(0);
    const dispatch = useDispatch();
    const roomInfo = props.room;
    const accessToken = useSelector(state=> state.auth.accessToken);

    const auth = getAuth();
    const navigate = useNavigate();    
    // console.log(props);
    const move = (room) => {
        navigate('/hotels/'+ props.hotel.id +'/' + roomInfo.room_id,{
            state:{
                hotel: props.hotel,
                room:roomInfo
            }
        });
    }
    const createOrderAsync = async () => {
        try {
            refreshToken()
            .then((newToken)=>{
                dispatch(setAccessToken(newToken));
                return newToken;
            })
            .then((newToken)=>{
                const reqData = {
                    accommodation_id: props.hotel.id,
                    room_id: props.room.room_id,
                    price_id: priceId,
                    order_check_in: changeToKR(startDate.startDate),
                    order_check_out: changeToKR(endDate.endDate),
                    order_total_guest: visitorCnt
                }
                createOrder(newToken, reqData).then((result)=>{
                    console.log(result)
                    alert('예약 요청이 되었습니다. 이용 가능 시 알림을 보내드립니다.')
                    setIsModal(false);
                    moveToMyReservePage();
                })
            })
            .catch((error)=>{
                console.log(error);
            })
            // const result = await createOrder(accessToken, reqData);

        } catch (error) {
            console.error('주문 생성 중 오류 발생:', error);
            switch(error) {
                case 'AUTH_TOKEN_EXPIRED':
                    console.log('토큰 만기로 토큰을 refresh 하겠습니다. ');
                    dispatch(setAccessToken(await refreshToken()));
                    break;
                case 'AUTH_CREDENTIALS_INVALID':
                    console.log('적절하지 않은 토큰(null)으로 토큰을 refresh 하겠습니다. ');
                    dispatch(setAccessToken(await refreshToken()));
                    break;
                case 'ORDER_INVALID': 
                    console.log(error);
              }       
        }
    };
    const moveReserve = (room) => {
        navigate('/hotels/'+ props.hotel +'/' + roomInfo.title +'/reserve',{
            state:{
                hotel: props.room,
                room:roomInfo
            }
        });
    }
    const moveToMyReservePage = () =>{
        navigate('/mypage/myReservation');
    }
    const getPrice = async() => {
        try {
            const data = await getPrices(props.room.room_id);
            setPriceId(data.result[0].price_id);
        } catch (error) {
            console.log(error);
            alert("가격을 받아오는 중 에러가 발생하였습니다. ")
        }
    }
    useEffect(()=>{
        getPrice();
    },[accessToken])
    useEffect(()=>{
    },[startDate, endDate])
    return(
        <SCardWrapper>
            <SCardTopSection>
                <SRoomTopLeft>
                    <CarouselWrapper param='hotels' slides={roomInfo.room_photos} width='100%'  radius="10px" body={false}></CarouselWrapper>
                </SRoomTopLeft>
                
            </SCardTopSection>
            <SRoomTopRight>
                    <SRoomTitle>
                        {roomInfo.title}
                    </SRoomTitle>
                    <SRoomCaptionWrapper>
                        <SRoomCaption>
                            {roomInfo.item.count_person}
                        </SRoomCaption>
                        <SRoomCaption>
                            입실/퇴실 : {roomInfo.item.check_in}시 ~ {roomInfo.item.check_out}시
                        </SRoomCaption>
                        <SRoomCaption>
                            {roomInfo.item.bed_type}
                        </SRoomCaption>
                    </SRoomCaptionWrapper>
                </SRoomTopRight>
            <SRoomDetailButoon onClick={() => {move(roomInfo)}}>
                객실 정보 상세<IconBox src={NextIcon}/>
            </SRoomDetailButoon>
            <div className="RoomCTA">
               <div 
                className="knock-cta-button2"
                onClick={() => {
                    onAuthStateChanged(auth, (user) =>{
                        if(user){
                            healthCheck().then(result => console.log('서버 헬스체크 결과 : ', result));
                        }else{
                            navigate('/login');
                        }
                    })
                    setIsModal(true);
                    // setModalOpen(true);
                }}>
                    {generatedformatCurrency(roomInfo.updated_price)} 예약하기
               </div>
               
                <Modal
                    isOpen={isModal}
                    height="100vh"
                    backgroundColor="rgb(0 0 0 / 68%);"
                    justifyContent="center">
                        <div>
                            <div className="top-section">
                                <div className="modal-box">
                                    <div className="modal-top-box">
                                        <i class="fa-solid fa-xmark" style={{cursor:"pointer"}} onClick={() => setIsModal(false)}></i> 
                                    </div>
                                    <h2>예약 정보</h2>
                                    <SInputWrapper>
                                    
                                        <Calender onStartDateChange={setStartDate} onEndDateChange={setEndDate} />
                                        <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">인원</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={visitorCnt}
                                            label="cnt"
                                            onChange={(e)=>setVisitorCnt(e.target.value)}
                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={6}>6</MenuItem>
                                            <MenuItem value={7}>7</MenuItem>
                                            <MenuItem value={8}>8</MenuItem>
                                            <MenuItem value={9}>9</MenuItem>
                                            <MenuItem value={10}>9+</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </SInputWrapper>
                                    <div >
                                        <p>요청 후 예약 가능 여부 확인 후 알려드립니다. </p>
                                        <p>24시간 내에 전달드립니다.</p>
                                    </div>
                                    <div 
                                        className="button-cta small"
                                        onClick={async () => {
                                            try {
                                                 await createOrderAsync();
                                            } catch (error) {
                                                console.error('Error during order creation:', error);
                                            }
                                        }}
                                    >
                                        요청하기
                                    </div>
                                </div>
                            </div>
                        </div>
                </Modal>
                    
            </div>
        </SCardWrapper>
    )
}
const SCardWrapper = styled.div`
    background-color:white;
    border-radius:10px;
    padding:20px 20px;
    margin: 20px 0px;
`;
const SCardTopSection = styled.div`

    display:flex;
`;
const SRoomTopLeft = styled.div`
    width:100%;
    // display: flex;
    align-items: center;
`;
const SRoomTopRight = styled.div`
    display:flex;
    flex-direction:column;
    padding:15px;
    justify-content: space-around;
`;
const SRoomTitle = styled.div`
    font-size:20px;
    font-weight:800;
`;
const SRoomCaptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px 0px;
    margin:10px 0px 0px 0px;
`
const SRoomCaption = styled.div`
    color:#999999;
    font-size:12px;
    font-weight:600;
`;
const SRoomDetailButoon = styled.div`
    font-size:14px;
    font-weight:700;
    display: flex;
    align-items: center;
    color:#333;
    text-decoration:none;
    text-align: center;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    padding: 10px 10px;
    margin: 10px 0px 0px 0px;
    justify-content: center;
    cursor:pointer;
`;
const IconBox = styled.img`
    width:16px;
`;
export default RoomCard;