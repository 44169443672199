import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Test = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    console.log(queryParams);
    const amount = queryParams.get('amount');
    const productId = queryParams.get('productId');
    console.log(productId);
    const productName = decodeURIComponent(queryParams.get('productName'));

    const clientKey = "test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq"
    const customerKey = "M5B66T-TZt2aJfaIIYT2Y" // 내 상점에서 고객을 구분하기 위해 발급한 고객의 고유 ID
    useEffect(()=>{
        const button = document.getElementById("payment-button")
        const paymentWidget = window.PaymentWidget(clientKey, window.PaymentWidget.ANONYMOUS) // 회원 결제
        paymentWidget.renderPaymentMethods(
            "#payment-method", 
            { value: amount },
            { variantKey: "DEFAULT" } // 렌더링하고 싶은 결제 UI의 variantKey
          )
        paymentWidget.renderAgreement('#agreement')
        button.addEventListener("click", function () {
            paymentWidget.requestPayment({
              orderId: 'k'+productId,            // 주문 ID(직접 만들어주세요)
              orderName: productName+amount,                 // 주문명
              successUrl: "https://localhost/",  // 결제에 성공하면 이동하는 페이지(직접 만들어주세요)
              failUrl: "https://my-store.com/fail",        // 결제에 실패하면 이동하는 페이지(직접 만들어주세요)
              customerEmail: "customer123@gmail.com",
              customerName: "김토스"
            })
          })
    },[])
    return(<>
        <div id="payment-method"></div>
        <div id="agreement"></div>
        <button id="payment-button">결제하기</button>
    </>)

}

export default Test;