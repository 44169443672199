import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SCardBody, SCardBodyBottom, SCardBodyImg, SCardBodyImgWrapper, SCardBodyLeft, SCardBodyRight, SCardBodyTop, SCardContainer, SCardFooter, SCardHeader, SCardHeaderLeft, SCardHeaderRight } from "./style";

const MyBookmarkCard = ({bookmark}) =>{

    return(
        <SCardContainer>
                <SCardHeader>
                    <SCardHeaderLeft>
                        숙소명
                    </SCardHeaderLeft>
                    <SCardHeaderRight className="link">
                        바로가기
                    </SCardHeaderRight>
                </SCardHeader>
                <SCardBody>
                    <SCardBodyLeft>
                        <SCardBodyImgWrapper>
                            <SCardBodyImg>
                            
                            </SCardBodyImg>
                        </SCardBodyImgWrapper>
                    </SCardBodyLeft>
                    <SCardBodyRight>
                        <SCardBodyTop>
                            상품명
                        </SCardBodyTop>
                        <SCardBodyBottom>
                            상품 가격
                        </SCardBodyBottom>
                    </SCardBodyRight>

                </SCardBody>
                
                <SCardFooter>
                </SCardFooter>
        </SCardContainer>
    )
}

export default MyBookmarkCard;