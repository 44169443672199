import Cookies from "js-cookie";
import { refreshAccessToken } from "./api";

export const checkEmail = (email) =>{
    let result = '';
    if(email=="" || !email.includes('@')) result = '이메일 형식에 맞게 입력해주세요. \n ex. knock@gmail.com';
    return result;
}
// else if(Object.values(isCheck).includes(false)){
//     setErrorMsg('이용 동의서에 동의하지 않으셨습니다.')
//   } 
export const checkPw = (pw, confirmdPw) =>{
    const passwordRegex = /^(?=.*[!@#$%^&*()_+\-=\[\]{};:\\"\\|,.<>\/?])(?=.*[a-zA-Z])(?=.*\d).*.{8,}$/;
    let result = '';
    if(pw === ""){
        result = '비밀번호를 입력하지 않으셨습니다.';
    } else if(!passwordRegex.test(pw)){
        result = '비밀번호는 대문자, 소문자, 숫자, 특수문자 1개 이상 \n 포함하여 8자 이상이어야 합니다.';
    } else if(pw !== confirmdPw){
        result = '비밀번호와 비밀번호 확인이 일치하지 않습니다';
    } 
    return result;
}

export const refreshToken = async() => {
    const refreshToken = Cookies.get('refreshToken');
    const idToken = Cookies.get('idToken');
    try {
        const newToken = await refreshAccessToken({accessToken:'', refreshToken, idToken})
        return newToken.access_token;
    } catch (error) {
        switch(error.detail[0].msg) {
            case 'Input should be a valid string':
                console.log('타당하지 않은 요청값이 있습니다.');
                break;
        }
    }
}