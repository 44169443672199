const generatedOrderId = () =>{
    const date = new Date();

    // YYYYMMDDHHMMSS 형식으로 날짜 포맷하기
    const formattedDate = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
  
    // 10000 ~ 99999 사이의 랜덤 숫자 추가하기
    const randomNum = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
    
    // 주문 ID 반환하기
    return `${formattedDate}-${randomNum}`;
}

const generatedformatCurrency = (number) =>{
    const numStr = number.toString();
    let result = '';
    let count = 0;

    for (let i = numStr.length - 1; i >= 0; i--) {
        count++;
        result = numStr[i] + result;
        if (count % 3 === 0 && i !== 0) {
            result = ',' + result;
        }
    }
    return result + '원';
}

const generatedPreviousformatDate = (date) =>{
    const oneDayInMilliseconds = 86400000; // 24*60*60*1000
    const previousDayTimestamp  = date - oneDayInMilliseconds;
    const previousDate = new Date(previousDayTimestamp);
    previousDate.setHours(0, 0, 0, 0);

    const month = previousDate.getMonth() + 1; // getMonth()는 0부터 시작하기 때문에 1을 더합니다.
    const day = previousDate.getDate();
    
    const formattedPreviousDate = `${month}월 ${day}일`;
    return formattedPreviousDate;
}
export {generatedformatCurrency, generatedOrderId, generatedPreviousformatDate} ;