import React, { useState } from "react";
import CouponPolicy from "./CouponPolicy";
import Modal from "../../components/Modal/Modal";
import { SCardBodyBottom, SCardBodyTop, SCardContainer, SCardFooter, SCardHeader, SCardHeaderLeft, SCardHeaderRight } from "./styles";
import { changeToKR, formatCurrency } from "../../utils/formatter";

const MyCouponCard = ({myCoupon}) =>{
    const [isModal, setIsModal] = useState(false);

    return(
        <SCardContainer>
                <SCardHeader>
                    <SCardHeaderLeft>
                        {myCoupon?.coupon_name}
                    </SCardHeaderLeft>
                </SCardHeader>
                <SCardBodyTop>
                    {formatCurrency(myCoupon?.coupon_discount_amount)}원
                </SCardBodyTop>
                <SCardBodyBottom>
                   {changeToKR(new Date())}  ~ {myCoupon?.expired_at}
                </SCardBodyBottom>
                <SCardFooter>
                    <div>
                        <i className="fa-solid fa-barcode"></i>
                        <i className="fa-solid fa-barcode"></i>
                        <i className="fa-solid fa-barcode"></i>
                    </div>
                  
                    <div className="cursor-pointer" onClick={()=>setIsModal(true)}>
                        유의사항
                        <i style={{margin:"0px 0px 0px 3px"}} className="fa-solid fa-angle-right"></i>
                    </div>
                   
                </SCardFooter>
                {
                    isModal && 
                    <Modal 
                        isOpen={isModal}
                        height="100vh"
                        backgroundColor="rgb(0 0 0 / 68%);"
                        justifyContent="center">

                        <div className="modal-box">
                            <div className="modal-box-close">
                                <i onClick={()=>setIsModal(false)} className="fa-solid fa-xmark cursor-pointer"></i>
                            </div>
                            <CouponPolicy title={myCoupon?.coupon_name}></CouponPolicy>
                        </div>
                    </Modal>
                }
                
        </SCardContainer>
    )
}

export default MyCouponCard;