import { Link } from "react-router-dom";
import styled from "styled-components";

/* Common */

export const SCouponSection = styled.div`
    height:100%;
`;

export const SBodyTitle = styled.div`
    display:flex;
    justify-content: space-between;
    padding:20px;
    font-weight:600;
`;
export const SBodyContent = styled.div`
    background-color:#F2F5F7;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px 0px;
    overflow:auto;
`

/* MyCouponCard.jsx */

export const SCardContainer = styled.div`
    width:85%;
    background-color:#fff;
    border-radius:20px 20px 20px 20px;
`
export const SCardHeader= styled.div`
    padding:20px 20px 10px 20px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-bottom:1px solid #f1f1f1;
`;

export const SCardBodyTop = styled.div`
    padding:20px 20px 10px 20px;
    font-size:24px;
    font-weight:800;
`;

export const SCardBodyBottom = styled.div`
    padding:0px 20px 20px 20px;
    color:#ccc;
`;

export const SCardFooter = styled.div`
    background-color:#ccc;
    padding:10px 20px;
    border-radius:0px 0px 20px 20px;
    font-size:12px;
    font-weight:600;
    text-align:right;
    display: flex;
    justify-content: space-between;
`;

export const SCardHeaderLeft = styled.div`
    font-size:18px;
    font-weight:900;
    color:#333;
`;

export const SCardHeaderRight = styled(Link)`
    font-size:12px;
`;

/* RegisterCoupon.jsx */

// export const SBodyTitle = styled.div`
//     display:flex;
//     justify-content: space-between;
//     padding:20px;
// `;
// export const SBodyContent = styled.div`
//     background-color:#F2F5F7;
//     height:90vh;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 20px;
//     padding: 10px 0px;
// `
export const SContentInputWrapper = styled.div`
    width:80%;
`;
export const SContentInput = styled.input`
    width:calc(100% - 20px);
    margin:20px 0px;
    padding: 15px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    font-size:14px;
`;
export const SContentInputButton = styled.div`
    padding:10px 5px;
    border-radius:5px;
`;